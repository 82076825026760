interface IOral {
  id: string;
  status: string;
  categoryId: number;
  title: string;
  filePath: string;
  createdAt: string;
  updatedAt: string;
  rejectReason: string;
}

interface OralStatusProps {
  oral: IOral;
  name: string;
}

const OralStatus = ({ oral, name }: OralStatusProps) => {
  const rawDate = new Date(oral.createdAt);

  const options = {
    year: "numeric" as const,
    month: "long" as const,
    day: "numeric" as const,
  };

  const formatted = rawDate.toLocaleDateString("ID-id", options);

  return (
    <div className="flex flex-col md:flex-row rounded-[20px] py-[24px] px-[20px] shadow-3xl gap-[20px] md:gap-[150px] w-full">
      <div className="flex flex-col gap-[10px] w-full">
        <h1 className="font-bold">{oral.title}</h1>
        <p>
          {name} / {formatted}
        </p>
      </div>
      <div className="w-[100px]">
        <span
          className={`px-[10px] py-[4px] rounded-[30px] bg-[#E6F4EE] text-[#008E58]`}
        >
          SUBMITTED
        </span>
      </div>
    </div>
  );
};

export default OralStatus;
