import { useEffect, useState } from "react";
import LayoutAdmin from "./LayoutAdmin";
import { axiosInstanceAdmin } from "../../utils/axios";
import { Pagination } from "../../components/Pagination";
import OralStatus from "../../components/admin/OralStatus";
import LoadingSpinner from "../../components/Loading";

export interface IUpload {
  file: any;
  title: string;
  categoryId: string;
}

export interface IOralAdmin {
  id: string;
  uploaderId: string;
  uploaderName: string;
  uploaderCategory: string;
  name: string;
  filePath: string;
  status: string;
  createdAt: string;
  abstractCategory: string;
  abstractStatus: string;
}

const OralAdmin = () => {
  const [active, setActive] = useState("");
  const [uploaderCategoryId, setUploaderCategoryId] = useState("");
  const [abstracts, setAbstracts] = useState<IOralAdmin[] | null>([]);
  const [toggle, setToggle] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(5);
  const [totalItem, setTotalItem] = useState(0);
  const [isLoading, setLoading] = useState(false);

  const next = () => {
    if (page === totalPage) return;

    setPage(page + 1);
  };

  const prev = () => {
    if (page === 1) return;

    setPage(page - 1);
  };

  useEffect(() => {
    const getOrderRegistrasi = async () => {
      try {
        setLoading(true);

        const response = await axiosInstanceAdmin.get(
          `/admin/presentation/slides?uploaderCategoryId=${uploaderCategoryId}&categoryId=${active}&page=${page}`
        );
        setAbstracts(response.data.result.slides);
        setTotalPage(response.data.result.totalPages);
        setTotalItem(response.data.result.total);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    getOrderRegistrasi();
  }, [active, uploaderCategoryId, toggle, page]);

  useEffect(() => {
    setPage(1);
  }, [active]);

  return (
    <LayoutAdmin>
      <div className="flex flex-col gap-[16px]">
        <div className="w-full bg-white rounded-[16px] p-[24px] font-bold text-[32px]">
          <h1>Presentasi Oral</h1>
        </div>
        <div className="flex flex-col w-full bg-white rounded-[16px] p-[24px] gap-[16px]">
          <h1 className="font-bold text-2xl">Kategori Peserta</h1>
          <div className="flex gap-[12px]">
            <button
              onClick={() => setActive("")}
              className={`flex px-[12px] py-[6px] rounded-[20px] items-center justify-center ${
                active === ""
                  ? "text-white bg-primary-100"
                  : "text-[#AFAFAF] bg-[#EBEBEB]"
              }`}
            >
              Semua
            </button>
            <button
              onClick={() => setActive("1")}
              className={`flex px-[12px] py-[6px] rounded-[20px] items-center justify-center ${
                active === "1"
                  ? "text-white bg-primary-100"
                  : "text-[#AFAFAF] bg-[#EBEBEB]"
              }`}
            >
              Anggota Biasa Perdoski
            </button>
            <button
              onClick={() => setActive("2")}
              className={`flex px-[12px] py-[6px] rounded-[20px] items-center justify-center ${
                active === "2"
                  ? "text-white bg-primary-100"
                  : "text-[#AFAFAF] bg-[#EBEBEB]"
              }`}
            >
              Anggota Muda Perdoski
            </button>
            <button
              onClick={() => setActive("4")}
              className={`flex px-[12px] py-[6px] rounded-[20px] items-center justify-center ${
                active === "4"
                  ? "text-white bg-primary-100"
                  : "text-[#AFAFAF] bg-[#EBEBEB]"
              }`}
            >
              Dokter Umum
            </button>
            <button
              onClick={() => setActive("3")}
              className={`flex px-[12px] py-[6px] rounded-[20px] items-center justify-center ${
                active === "3"
                  ? "text-white bg-primary-100"
                  : "text-[#AFAFAF] bg-[#EBEBEB]"
              }`}
            >
              Dokter Spesialis Lain
            </button>
          </div>
          <h1 className="font-bold text-2xl">Status Presentasi Oral</h1>
          <div className="flex gap-[12px]">
            <button
              onClick={() => setUploaderCategoryId("")}
              className={`flex px-[12px] py-[6px] rounded-[20px] items-center justify-center ${
                uploaderCategoryId === ""
                  ? "text-white bg-primary-100"
                  : "text-[#AFAFAF] bg-[#EBEBEB]"
              }`}
            >
              Semua
            </button>
            <button
              onClick={() => setUploaderCategoryId("1")}
              className={`flex px-[12px] py-[6px] rounded-[20px] items-center justify-center ${
                uploaderCategoryId === "1"
                  ? "text-white bg-primary-100"
                  : "text-[#AFAFAF] bg-[#EBEBEB]"
              }`}
            >
              Laporan Khusus
            </button>
            <button
              onClick={() => setUploaderCategoryId("2")}
              className={`flex px-[12px] py-[6px] rounded-[20px] items-center justify-center ${
                uploaderCategoryId === "2"
                  ? "text-white bg-primary-100"
                  : "text-[#AFAFAF] bg-[#EBEBEB]"
              }`}
            >
              Penelitian
            </button>
          </div>
        </div>

        <div className="flex flex-col bg-white rounded-[16px] p-[24px] items-center gap-[16px]">
          <table className="min-w-full">
            <thead className="">
              <tr>
                <th scope="col" className="">
                  ID Peserta
                </th>
                <th scope="col" className="">
                  Nama Peserta
                </th>
                <th scope="col" className="">
                  Kategori Peserta
                </th>
                <th scope="col" className="">
                  Kategori Presentasi Oral
                </th>
                <th scope="col" className="">
                  Judul Presentasi
                </th>
                <th scope="col" className="">
                  Presentasi Oral
                </th>
                <th scope="col" className="">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {isLoading && (
                <tr>
                  <td colSpan={7} className="text-center py-6">
                    <LoadingSpinner />
                  </td>
                </tr>
              )}
              {!isLoading &&
                abstracts &&
                (abstracts?.length > 0 ? (
                  abstracts.map((order) => (
                    <OralStatus
                      data={order}
                      toggle={toggle}
                      setToggle={setToggle}
                    />
                  ))
                ) : (
                  <tr>
                    <td colSpan={7} className="text-center py-6">
                      no result
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="flex justify-between px-4">
          <div>
            <span className="font-bold">{totalItem}</span> results
          </div>
          <Pagination
            page={page}
            totalPage={totalPage}
            next={next}
            prev={prev}
          />
        </div>
      </div>
    </LayoutAdmin>
  );
};

export default OralAdmin;
