import * as React from "react";

export const PendaftarContext =
  React.createContext<PendaftarContextType | null>(null);

export interface IPendaftar {
  id: number;
  name: string;
  email: string;
  categoryId: number;
  phone: string;
  NPAPerdoski: string;
  NPAIDI: string;
  cabang: string;
  fileSTR: string;
  events: (string | number)[];
  nik: string;
}

export type PendaftarContextType = {
  listPendaftar: IPendaftar[];
  savePendaftar: (data: IPendaftar) => void;
  updatePendaftar: (id: number, data: IPendaftar) => void;
  deletePendaftar: (id: number) => void;
  resetPendaftar: () => void;
};

interface PendaftarProviderProps {
  children: React.ReactNode;
}

const RegistrasiProvider = ({ children }: PendaftarProviderProps) => {
  const [listPendaftar, setListPendaftar] = React.useState<IPendaftar[]>([]);

  const savePendaftar = (data: IPendaftar) => {
    const newPendaftar: IPendaftar = {
      id: Math.random(),
      name: data.name,
      email: data.email,
      categoryId: data.categoryId,
      phone: data.phone,
      NPAPerdoski: data.NPAPerdoski,
      NPAIDI: data.NPAIDI,
      cabang: data.cabang,
      fileSTR: data.fileSTR,
      events: data.events,
      nik: data.nik,
    };
    setListPendaftar([...listPendaftar, newPendaftar]);
  };

  const resetPendaftar = () => {
    setListPendaftar([]);
  };

  const updatePendaftar = (id: number, data: IPendaftar) => {
    setListPendaftar((prevList) =>
      prevList.map((pendaftar: IPendaftar) => {
        if (pendaftar.id === id) {
          return {
            ...pendaftar,
            name: data.name,
            email: data.email,
            categoryId: data.categoryId,
            phone: data.phone,
            NPAPerdoski: data.NPAPerdoski,
            NPAIDI: data.NPAIDI,
            cabang: data.cabang,
            fileSTR: data.fileSTR,
            events: data.events,
          };
        }
        return pendaftar;
      })
    );
  };

  const deletePendaftar = (id: number) => {
    setListPendaftar((prevList) =>
      prevList.filter((pendaftar: IPendaftar) => pendaftar.id !== id)
    );
  };

  return (
    <PendaftarContext.Provider
      value={{
        listPendaftar,
        savePendaftar,
        updatePendaftar,
        deletePendaftar,
        resetPendaftar,
      }}
    >
      {children}
    </PendaftarContext.Provider>
  );
};

export default RegistrasiProvider;
