import { useState } from "react";
import { IBroadcast } from "../../pages/admin-dashboard/Whatsapp";
import BroadcastDetail from "./BroadcastDetail";

interface BroadcastStatusProps {
  broadcast: IBroadcast;
  type: string;
}

const BroadcastStatus = ({ broadcast, type }: BroadcastStatusProps) => {
  const [showUploadModal, setShowUploadModal] = useState(false);

  return (
    <div className="flex flex-col md:flex-row rounded-[20px] py-[24px] px-[20px] shadow-3xl gap-[20px] md:gap-[150px] w-full">
      <div className="flex flex-col gap-[10px] w-full">
        {type === "email" && (
          <p className="font-bold">{broadcast.params.subject}</p>
        )}
        <h1 className={type === "whatsapp" ? "font-bold" : ""}>
          {broadcast.initiator} / {broadcast.createdAt} / Terkirim ke{" "}
          {broadcast.sentTo} dari {broadcast.of} Penerima
        </h1>

        <button
          onClick={() => setShowUploadModal(true)}
          className="relative cursor-pointer rounded-[30px] w-fit bg-white text-primary-100 border-primary-100 border-[1px] font-semibold py-2 px-3 inline-flex items-center"
        >
          <span>Lihat Pesan</span>
        </button>
        {showUploadModal && (
          <BroadcastDetail
            type={type}
            data={broadcast}
            setShowModal={setShowUploadModal}
          />
        )}
      </div>
      <div className="w-[100px]">
        <span className="px-[10px] py-[4px] rounded-[30px] bg-[#E6F4EE] text-[#008E58]">
          Terkirim
        </span>
      </div>
    </div>
  );
};

export default BroadcastStatus;
