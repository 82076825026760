import { IUpload } from "../../pages/dashboard/Abstract";
import { useState } from "react";
import uploadLogo from "../../assets/images/upload.svg";
import axios, { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import { axiosInstanceUser } from "../../utils/axios";
import abstrakLogo from "../../assets/images/abstract_logo_red.svg";

interface UploadAbstractFormProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  isReupload: boolean;
}

const UploadAbstractForm = ({
  setShowModal,
  isReupload,
}: UploadAbstractFormProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState<IUpload>({
    title: "",
    categoryId: "1",
    file: null,
  });

  const [errors, setErrors] = useState({
    title: false,
    categoryId: false,
    file: false,
  });

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    validateInput(name, value);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];

    if (file) {
      if (file.type === "application/pdf") {
        setFormData({ ...formData, file: file });
        setErrors({ ...errors, file: false });
      } else {
        setFormData({ ...formData, file: null });
        setErrors({ ...errors, file: true });
      }
    }
  };

  const validateInput = (name: string, value: any) => {
    const newErrors = { ...errors };

    if (name === "title") {
      newErrors.title = value === "";
    }

    if (name === "categoryId") {
      newErrors.categoryId = value === "";
    }

    setErrors(newErrors);
  };

  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const formDataRequest = new FormData();
      formDataRequest.append("file", formData.file);
      formDataRequest.append("title", formData.title);
      formDataRequest.append("categoryId", formData.categoryId);

      var response;
      if (isReupload) {
        response = await axiosInstanceUser.put(
          "/abstracts/upload",
          formDataRequest
        );
      } else {
        response = await axiosInstanceUser.post(
          "/abstracts/upload",
          formDataRequest
        );
      }
      enqueueSnackbar("Unggah Abstract berhasil", {
        autoHideDuration: 3000,
        variant: "success",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          const responseStatus = axiosError.response.status;
          if (responseStatus === 400) {
            enqueueSnackbar("Abstrak sudah pernah diupload", {
              autoHideDuration: 3000,
              variant: "success",
              anchorOrigin: { vertical: "top", horizontal: "right" },
            });
          } else {
            enqueueSnackbar("Gagal mengupload abstrak", {
              autoHideDuration: 3000,
              variant: "error",
              anchorOrigin: { vertical: "top", horizontal: "right" },
            });
          }
        }
      }
    } finally {
      setIsLoading(false);
    }
    setShowModal(false);
  };

  const isValid = () => {
    return (
      !(errors.title || errors.categoryId || errors.file) &&
      formData.title.length > 0 &&
      formData.categoryId.length > 0 &&
      formData.file
    );
  };

  return (
    <>
      <div className="flex flex-col gap-[10px] overflow-y-auto">
        <form className="flex flex-col m-auto gap-[16px]">
          <div>
            <label htmlFor="email" className="block text-xl font-bold">
              Judul Abstrak<span className="text-red-500">*</span>:
            </label>
            <input
              type="text"
              id="title"
              name="title"
              value={formData.title}
              onChange={handleChange}
              defaultValue={"1"}
              className={`mt-1 py-2 px-4 border-[1px] border-[#BABDCC] w-[400px] rounded-md bg-white ${
                errors.title
                  ? "border-red-500 focus:ring-0 focus:outline-none focus:border-red-500"
                  : "border-[#BABDCC]"
              }`}
              placeholder="Masukan judul abstract disini"
            />
            <p
              className={`mt-1 text-sm ${
                errors.title ? "text-red-500" : "text-gray-500"
              }`}
            >
              {errors.title ? "Judul tidak boleh kosong" : ""}
            </p>
          </div>

          <div>
            <label htmlFor="cabang" className="block text-xl font-bold">
              Kategori <span className="text-red-500">*</span>:
            </label>
            <select
              id="categoryId"
              name="categoryId"
              value={formData.categoryId}
              onChange={handleChange}
              className={`mt-1 py-2 px-4 border-[1px] border-[#BABDCC] rounded-md w-[400px] bg-white ${
                errors.categoryId
                  ? "border-red-500 focus:ring-0 focus:outline-none focus:border-red-500"
                  : "border-[#BABDCC]"
              }`}
            >
              {/* <option value="" className="text-[#AFAFAF]" disabled>Pilih Kategori</option> */}
              <option value="1">Laporan Kasus</option>
              <option value="2">Penelitian</option>
            </select>
            <p
              className={`mt-1 text-sm ${
                errors.categoryId ? "text-red-500" : "text-gray-500"
              }`}
            >
              {errors.categoryId ? "Cabang tidak boleh kosong" : ""}
            </p>
          </div>
          <div>
            <label htmlFor="file" className="block text-xl font-bold mb-2">
              Unggah <span className="text-red-500">*</span>:
            </label>
            {formData.file && (
              <div className="flex shadow-3xl bg-primary-500 rounded-[20px] p-[20px] mb-4 font-bold gap-[12px] mx-1">
                <img src={abstrakLogo} alt="logo" />
                {formData.file.name}
              </div>
            )}
            <label
              htmlFor="file"
              className="relative cursor-pointer rounded-[30px] bg-white text-primary-100  border-primary-100 border-[1px] font-semibold py-2 px-4 inline-flex items-center"
            >
              {!formData.file && <img src={uploadLogo} alt="logo" />}
              <span>{formData.file ? "Ubah File" : "Unggah"}</span>
              <input
                type="file"
                accept=".pdf"
                id="file"
                name="file"
                onChange={handleFileChange}
                className="sr-only"
              />
            </label>
            <p
              className={`mt-1 text-sm ${
                errors.file ? "text-red-500" : "text-gray-500"
              }`}
            >
              {errors.file ? "Mohon upload file pdf" : ""}
            </p>
          </div>
          <div className="flex flex-col gap-[8px]">
            {!isLoading ? (
              <button
                className={`text-white border-0 rounded-[30px] p-[8px] ${
                  isValid() ? "bg-primary-100" : "bg-disabled"
                }`}
                onClick={handleSubmit}
                disabled={!isValid()}
              >
                Unggah
              </button>
            ) : (
              <button
                disabled
                type="button"
                className="text-white border-0 rounded-[30px] p-[8px] bg-primary-100"
              >
                <svg
                  aria-hidden="true"
                  role="status"
                  className="inline w-4 h-4 me-3 text-white animate-spin"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#E5E7EB"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentColor"
                  />
                </svg>
                Unggah...
              </button>
            )}
            <button
              className="bg-white text-primary-100 border-0 hover:bg-[#C1C1C1] rounded-[30px] p-[8px] hover:text-white"
              onClick={() => setShowModal(false)}
            >
              Batal
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default UploadAbstractForm;
