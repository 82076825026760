import { IPendaftar } from "../../context/AppContext";
import AnggotaForm from "./AnggotaForm";

interface RegistrationFormProps {
  role: string;
  formData: IPendaftar;
  isValid: boolean;
  setFormData: React.Dispatch<React.SetStateAction<IPendaftar>>;
  setIsValid: React.Dispatch<React.SetStateAction<boolean>>;
  onNext: () => void;
}

const RegistrationAnggota = ({
  role,
  formData,
  setFormData,
  isValid,
  setIsValid,
  onNext,
}: RegistrationFormProps) => {
  return (
    <div className="static">
      <div className="flex flex-col px-[28px] sm:px-[48px] md:px-[66px] py-[28px] gap-[14px] md:gap-[28px] max-h-screen overflow-y-scroll pb-[200px] sm:pb-[260px] lg:pb-[120px]">
        <h1 className="font-semibold text-[#BDBDBD] text-[14px] sm:text-[18px] md:text-[28px]">
          {role}
        </h1>
        <div></div>
        <AnggotaForm
          role={role}
          formData={formData}
          setFormData={setFormData}
          setIsValid={setIsValid}
          isPrice={true}
        />
      </div>
      <div className="absolute bottom-0 left-0 w-full border-t-2 bg-white">
        <button
          className={`px-[40px] py-[8px] mx-auto my-4 rounded-[30px] block lg:hidden shadow-3xl text-white ${
            isValid ? "bg-primary-100" : "bg-disabled"
          }`}
          onClick={onNext}
          disabled={!isValid}
        >
          Lanjut
        </button>
      </div>
    </div>
  );
};

export default RegistrationAnggota;
