import { IAbstractView } from "../../pages/dashboard/Abstract";

interface AbstractStatusProps {
  abstract: IAbstractView;
}

const ViewAbstract = ({ abstract }: AbstractStatusProps) => {
  return (
    <div className="flex flex-col md:flex-row rounded-[20px] py-[24px] px-[20px] shadow-3xl gap-[20px] md:gap-[150px] w-full">
      <div className="flex flex-col gap-[10px] w-full">
        <h1 className="font-bold">{abstract.name}</h1>
        <p>
          {abstract.uploaderName} / {abstract.kategori}
        </p>
      </div>
    </div>
  );
};

export default ViewAbstract;
