import { IPendaftar } from "../../context/AppContext";
import { useEffect, useState } from "react";
import { axiosInstanceAdmin } from "../../utils/axios";

interface RegistrationFormProps {
  formData: IPendaftar;
  isPrice: boolean;
}

interface IWorkshop {
  id: number;
  name: string;
  price: number;
}

const DokterViewForm = ({ formData, isPrice }: RegistrationFormProps) => {
  const [simposiumPrice, setSimposiumPrice] = useState(0);
  const [workshops, setWorkshops] = useState<IWorkshop[]>([]);
  const [pendamping, setPendamping] = useState<IWorkshop[]>([]);
  const [acara, setAcara] = useState<IWorkshop[]>([]);

  useEffect(() => {
    const getWorkshopsData = async () => {
      try {
        if (formData.categoryId) {
          const response = await axiosInstanceAdmin.get(
            `/products/${formData.categoryId}`
          );
          setWorkshops(response.data.result.workshops);
          setAcara(response.data.result.acara);
          setPendamping(response.data.result.pendamping);
          setSimposiumPrice(response.data.result.basePrice);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const getImageStrLink = async () => {
      try {
        const path = formData.fileSTR.substring(
          formData.fileSTR.indexOf("/") + 1
        );

        const response = await axiosInstanceAdmin.get(
          `/admin/users/str/${path}`
        );
        setPreviewUrl(response.data.result.url);
      } catch (error) {
        // console.log(error);
      }
    };
    getWorkshopsData();
    getImageStrLink();
  }, []);

  const [previewUrl, setPreviewUrl] = useState<string | null>(null);

  return (
    <div className="flex flex-col gap-[28px]">
      <div className="flex flex-col gap-[20px] md:gap-[40px]">
        <div>
          <label
            htmlFor="name"
            className="block text-[12px] sm:text-xl font-bold"
          >
            Nama dan Gelar <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            className={`mt-1 py-2 px-4 border-[1px] rounded-md w-full md:w-[616px] bg-white border-[#BABDCC] text-[#9B9B9B]`}
            placeholder="Masukan nama dan gelar anda disini"
            disabled
          />
        </div>

        <div>
          <label
            htmlFor="lastName"
            className="block text-[12px] sm:text-xl font-bold"
          >
            No telepon<span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            id="phone"
            name="phone"
            value={formData.phone}
            required
            className={`mt-1 py-2 px-4 border-[1px] rounded-md w-full md:w-[616px] bg-white border-[#BABDCC] text-[#9B9B9B]`}
            placeholder="Masukan No Telepon anda disini"
            disabled
          />
        </div>

        <div>
          <label
            htmlFor="email"
            className="block text-[12px] sm:text-xl font-bold"
          >
            Email<span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            id="email"
            name="email"
            value={formData.email}
            required
            className={`mt-1 py-2 px-4 border-[1px] rounded-md w-full md:w-[616px] bg-white border-[#BABDCC] text-[#9B9B9B]`}
            placeholder="Masukan alamat Email anda disini"
            disabled
          />
        </div>

        <div>
          <label
            htmlFor="noAnggota"
            className="block text-[12px] sm:text-xl font-bold"
          >
            Nomor Pokok Anggota IDI<span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            id="NPAIDI"
            name="NPAIDI"
            value={formData.NPAIDI}
            required
            className={`mt-1 py-2 px-4 border-[1px] rounded-md w-full md:w-[616px] bg-white border-[#BABDCC] text-[#9B9B9B]`}
            placeholder="Masukan nomor pokok anggota anda disini"
            disabled
          />
        </div>

        <div>
          <label
            htmlFor="file"
            className="block text-[12px] sm:text-xl font-bold mb-2"
          >
            Unggah STR <span className="text-red-500">*</span>
          </label>
          {previewUrl && (
            <img
              src={previewUrl}
              alt="File Preview"
              className="my-4 w-[400px] h-auto"
            />
          )}
        </div>

        <div>
          <div className="flex gap-[10px]">
            <label
              htmlFor="acara"
              className="block text-[12px] sm:text-xl font-bold"
            >
              Acara yang ingin diikuti
            </label>
          </div>
          <div className="flex flex-col md:flex-row gap-4 md:gap-0">
            <div className="pr-12">
              <label className="container">
                <span className="text-[#9B9B9B]">Simposium</span>
                <input
                  type="checkbox"
                  name="simposium"
                  checked={true}
                  disabled={true}
                />
                <span className="checkmark checkmark-disabled"></span>
              </label>
              {isPrice && (
                <p className="font-semibold text-[14px] text-[#C1C1C1]">
                  <span className="text-[#DC989E]">
                    Rp.
                    {simposiumPrice && simposiumPrice.toLocaleString("id-ID")}
                  </span>{" "}
                  per tiket
                </p>
              )}
            </div>
            {acara &&
              acara.map((acara) => (
                <div>
                  <label className="container text-[#9B9B9B]">
                    {acara.name}
                    <input
                      type="checkbox"
                      name={acara.id.toString()}
                      checked={formData.events.includes(acara.id)}
                      disabled={true}
                    />
                    <span className="checkmark"></span>
                  </label>
                  {isPrice && (
                    <p className="font-semibold text-[14px] text-[#C1C1C1]">
                      <span className="text-[#DC989E]">
                        Rp.{acara.price.toLocaleString("id-ID")}
                      </span>{" "}
                      per tiket
                    </p>
                  )}
                </div>
              ))}
          </div>
        </div>

        {workshops.length > 0 && (
          <div>
            <label
              htmlFor="workshop"
              className="block text-[12px] sm:text-xl font-bold"
            >
              Workshop
            </label>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 max-w-[920px]">
              {workshops &&
                workshops.map((workshop) => (
                  <div className="pr-12">
                    <label className="container text-[#9B9B9B]">
                      {workshop.name}
                      <input
                        type="checkbox"
                        name={workshop.id.toString()}
                        checked={formData.events.includes(workshop.id)}
                        disabled={true}
                      />
                      <span className="checkmark"></span>
                    </label>
                    {isPrice && (
                      <p className="font-semibold text-[14px] text-[#9B9B9B]">
                        <span className="text-[#DC989E]">
                          Rp.{workshop.price.toLocaleString("id-ID")}
                        </span>{" "}
                        per tiket
                      </p>
                    )}
                  </div>
                ))}
            </div>
          </div>
        )}

        <div>
          <label
            htmlFor="pendamping"
            className="block text-[12px] sm:text-xl font-bold"
          >
            Pendamping
          </label>
          <div className="grid grid-cols-1 lg:grid-cols-4 gap-4 max-w-[920px]">
            {pendamping &&
              pendamping.map((pendamping) => (
                <div className="pr-12">
                  <label className="container text-[#9B9B9B]">
                    {pendamping.name}
                    <input
                      type="checkbox"
                      name={pendamping.id.toString()}
                      checked={formData.events.includes(pendamping.id)}
                      disabled={true}
                    />
                    <span className="checkmark"></span>
                  </label>
                  {isPrice && (
                    <p className="font-semibold text-[14px] text-[#9B9B9B]">
                      <span className="text-[#DC989E]">
                        Rp.{pendamping.price.toLocaleString("id-ID")}
                      </span>{" "}
                      per tiket
                    </p>
                  )}
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DokterViewForm;
