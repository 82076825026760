import logo from "../assets/images/konas_logo.svg";
import illustration from "../assets/images/konas_illustration.svg";
import { useNavigate } from "react-router-dom";
import { useState, useContext, useEffect } from "react";
import { PendaftarContext, PendaftarContextType } from "../context/AppContext";

const Home = () => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState<string>("");
  const { resetPendaftar } = useContext(
    PendaftarContext
  ) as PendaftarContextType;

  const handleOptionClick = (option: string) => {
    setSelectedOption(option);
  };

  const handleNextClick = () => {
    navigate("/registration", { state: { selectedOption } });
  };

  useEffect(() => {
    resetPendaftar();
  }, [selectedOption]);

  return (
    <div className="flex flex-col lg:flex-row px-4 lg:p-0 min-h-screen">
      <div className="flex flex-col lg:w-1/2 justify-center items-center">
        <div className="flex flex-col items-center">
          <img src={logo} alt="logo" className="w-[128px] mt-16 lg:mt-0" />
          <img
            src={illustration}
            alt="illustration"
            className="lg:max-w-[400px] xl:max-w-[522px] hidden lg:block"
          />
        </div>
        <footer className="text-sm font-medium mt-24 hidden lg:block">
          v0.8.1 - © 2023 Konas Perdoski. All rights Reserved. Developed by PT
          Aigov Teknologi Asia
        </footer>
      </div>
      <div className="flex flex-col lg:w-1/2 justify-center items-center lg:rounded-l-[60px] lg:shadow-3xl">
        <div className="lg:w-[420px]">
          <p className="text-xl lg:text-2xl font-semibold mb-4">
            Daftar sebagai?
          </p>
          <div className="flex flex-col gap-4 items-center">
            <button
              className={`w-full py-[20px] lg:py-[35px] px-[16px] lg:px-[27px] rounded-[20px] text-[14px] lg:text-base shadow-3xl ${
                selectedOption ===
                "Anggota Biasa Perdoski (Sp.KK/ Sp.DV/ Sp.DVE)"
                  ? "border-primary-50 border-2"
                  : ""
              }`}
              onClick={() =>
                handleOptionClick(
                  "Anggota Biasa Perdoski (Sp.KK/ Sp.DV/ Sp.DVE)"
                )
              }
            >
              Anggota Biasa Perdoski (Sp.KK/ Sp.DV/ Sp.DVE)
            </button>
            <button
              className={`w-full py-[20px] lg:py-[35px] px-[16px] lg:px-[27px] rounded-[20px] shadow-3xl ${
                selectedOption === "Anggota Muda Perdoski (Residen)"
                  ? "border-primary-50 border-2"
                  : ""
              }`}
              onClick={() =>
                handleOptionClick("Anggota Muda Perdoski (Residen)")
              }
            >
              Anggota Muda Perdoski (Residen)
            </button>
            <button
              className={`w-full py-[20px] lg:py-[35px] px-[16px] lg:px-[27px] rounded-[20px] shadow-3xl ${
                selectedOption === "Dokter Umum"
                  ? "border-primary-50 border-2"
                  : ""
              }`}
              onClick={() => handleOptionClick("Dokter Umum")}
            >
              Dokter Umum
            </button>
            <button
              className={`w-full  py-[20px] lg:py-[35px] px-[16px] lg:px-[27px] rounded-[20px] shadow-3xl ${
                selectedOption === "Dokter Spesialis Lain"
                  ? "border-primary-50 border-2"
                  : ""
              }`}
              onClick={() => handleOptionClick("Dokter Spesialis Lain")}
            >
              Dokter Spesialis Lain
            </button>
            <button
              className={`w-full py-[8px] lg:py-[12px] px-[27px] rounded-[30px] my-8 shadow-3xl text-white ${
                selectedOption ? "bg-primary-100" : "bg-primary-50"
              }`}
              disabled={!selectedOption}
              onClick={() => handleNextClick()}
            >
              Lanjut
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
