import { Navigator } from "./routes/navigator";
import RegistrasiProvider from "./context/AppContext";

function App() {
  return (
    <RegistrasiProvider>
      <Navigator />
    </RegistrasiProvider>
  );
}

export default App;
