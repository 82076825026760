import {
  IPendaftar,
  PendaftarContext,
  PendaftarContextType,
} from "../../context/AppContext";
import { useContext } from "react";
import AnggotaForm from "./AnggotaForm";
import { useSnackbar } from "notistack";
import { FiChevronLeft } from "react-icons/fi";

interface RegistrationModalProps {
  role: string;
  setShowModal: any;
  formData: IPendaftar;
  isValid: boolean;
  setFormData: React.Dispatch<React.SetStateAction<IPendaftar>>;
  setIsValid: React.Dispatch<React.SetStateAction<boolean>>;
}
const AnggotaModal = ({
  role,
  setShowModal,
  formData,
  isValid,
  setFormData,
  setIsValid,
}: RegistrationModalProps) => {
  const { savePendaftar } = useContext(
    PendaftarContext
  ) as PendaftarContextType;
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = () => {
    savePendaftar(formData);
    setShowModal(false);
    enqueueSnackbar(`Pendaftaran ${formData.name} berhasil ditambahkan`, {
      autoHideDuration: 5000,
      variant: "success",
      anchorOrigin: { vertical: "top", horizontal: "right" },
    });
    setFormData({
      id: 0,
      name: "",
      email: "",
      categoryId: formData.categoryId,
      phone: "",
      NPAPerdoski: "",
      NPAIDI: "",
      cabang: "",
      fileSTR: "",
      events: [],
      nik: "",
    });
  };

  return (
    <div>
      <div className="pointer-events-none fixed inset-0 bg-black opacity-50 z-[60] cursor-pointer" />
      <div className="fixed inset-0 flex  items-center justify-center z-[70] ">
        <div className="flex flex-col w-full h-full sm:w-[85%] sm:max-w-[976px] m-auto sm:rounded-[24px] md:rounded-[8px] shadow-[0_0px_20px_0px_rgb(25,61,111,0.25)] bg-white sm:overflow-y-auto sm:max-h-[80vh]">
          <div className="p-[24px] flex justify-between border-b border-gray-100">
            <div className="flex justify-center items-center gap-[8px]">
              <button onClick={() => setShowModal(false)} className="sm:hidden">
                <FiChevronLeft className="h-[25px] w-[25px]" />
              </button>
              <h1 className="text-base sm:text-xl font-bold">Tambah Peserta</h1>
            </div>
            <div className="hidden sm:flex">
              <button
                onClick={() => setShowModal(false)}
                className="flex mr-2 px-[4px] py-[4px] w-[117px] m-auto rounded-[30px] items-center justify-center text-primary-100 font-semibold bg-white"
              >
                Batal
              </button>
              <button
                onClick={handleSubmit}
                disabled={!isValid}
                className={`flex px-[4px] py-[4px] w-[117px] m-auto rounded-[30px] items-center justify-center shadow-3xl text-white ${
                  isValid ? "bg-primary-100" : "bg-disabled"
                }`}
              >
                Simpan
              </button>
            </div>
          </div>
          <form className="flex flex-col gap-[40px] overflow-y-auto p-8 ">
            <AnggotaForm
              role={role}
              formData={formData}
              setFormData={setFormData}
              setIsValid={setIsValid}
              isPrice={true}
            />
          </form>
          <div className="p-[24px] flex justify-between border-t border-gray-100 border-t-1 shadow-3xl sm:hidden">
            <button
              onClick={handleSubmit}
              disabled={!isValid}
              className={`flex px-[4px] py-[4px] w-full m-auto rounded-[30px] items-center justify-center shadow-3xl text-white ${
                isValid ? "bg-primary-100" : "bg-disabled"
              }`}
            >
              Simpan
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnggotaModal;
