import personIllustration from "../assets/images/person_illustration.svg";
import deleteIcon from "../assets/images/delete.svg";
import successIcon from "../assets/images/success.svg";
import { useContext, useEffect, useState } from "react";
import {
  PendaftarContext,
  PendaftarContextType,
  IPendaftar,
} from "../context/AppContext";

import EditAnggotaModal from "./anggota/EditAnggotaModal";
import EditDokterModal from "./dokter/EditDokterModal";
import { axiosInstance } from "../utils/axios";
import AddAnggotaModal from "./anggota/AddAnggotaModal";
import AddDokterModal from "./dokter/AddDokterModal";
import { useSnackbar } from "notistack";

interface SummaryProps {
  role: string;
  formData: IPendaftar;
  isValid: boolean;
  setFormData: React.Dispatch<React.SetStateAction<IPendaftar>>;
  setIsValid: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
}

interface IPeserta {
  name: string;
  email: string;
  categoryId: number;
  phone: string;
  NPAPerdoski: string;
  NPAIDI: string;
  cabang: string;
  fileSTR: string;
  workshops: (string | number)[];
  nik: string;
}

interface IPayload {
  email: string;
  peserta: IPeserta[];
}

interface IWorkshop {
  id: number;
  name: string;
  price: number;
}

interface IPriceSummary {
  id: number;
  name: string;
  qty: number;
  totalPrice: number;
}

const Summary = ({
  role,
  formData,
  isValid,
  setFormData,
  setIsValid,
  setCurrentStep,
}: SummaryProps) => {
  const [simposiumPrice, setSimposiumPrice] = useState(0);
  const [workshops, setWorkshops] = useState<IWorkshop[]>([]);
  const [pendamping, setPendamping] = useState<IWorkshop[]>([]);
  const [acara, setAcara] = useState<IWorkshop[]>([]);

  useEffect(() => {
    const getWorkshopsData = async () => {
      try {
        if (formData.categoryId) {
          const index = formData.categoryId - 1;
          const response = await axiosInstance.get(`/products`);
          setWorkshops(response.data.result[index].workshops);
          setAcara(response.data.result[index].acara);
          setPendamping(response.data.result[index].pendamping);
          setSimposiumPrice(response.data.result[index].basePrice);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    getWorkshopsData();
  }, []);

  const isAnggota =
    role === "Anggota Biasa Perdoski (Sp.KK/ Sp.DV/ Sp.DVE)" ||
    role === "Anggota Muda Perdoski (Residen)";

  const [email, setEmail] = useState("");
  const [mergeArray, setMergeArray] = useState<IWorkshop[]>([]);
  const [workshopArray, setWorkshopArray] = useState<IWorkshop[]>([]);
  const [priceSummary, setPriceSummary] = useState<IPriceSummary[]>([]);

  const { enqueueSnackbar } = useSnackbar();

  const { listPendaftar, deletePendaftar } = useContext(
    PendaftarContext
  ) as PendaftarContextType;
  const [showRegistrationModal, setShowRegistrationModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [id, setId] = useState(0);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [transactionPrice, setTransactionPrice] = useState(0);

  useEffect(() => {
    if (acara && workshops && pendamping) {
      setMergeArray([...acara, ...workshops, ...pendamping]);
      setWorkshopArray([...workshops, ...pendamping]);
    }
  }, [acara, workshops, pendamping]);

  const handleDelete = (id: number) => {
    (document.getElementById("my_modal_4") as HTMLDialogElement)?.showModal();
    setId(id);
  };

  const handleSubmitDelete = () => {
    deletePendaftar(id);
    enqueueSnackbar("Pendaftaran berhasil dihapus", {
      autoHideDuration: 1000,
      variant: "success",
      anchorOrigin: { vertical: "top", horizontal: "right" },
    });
  };

  const getListWorkshop = (arr: number[]) => {
    const sortedArray = arr.sort((a, b) => a - b);
    const dataMap = new Map(workshopArray.map((item) => [item.id, item]));
    const resultString = sortedArray
      .map((id) => dataMap.get(id)?.name)
      .filter((name) => name !== undefined)
      .join(", ");

    return resultString;
  };

  useEffect(() => {
    if (mergeArray.length > 1 && workshopArray.length > 1) {
      const calculateSummary = () => {
        const priceSummary: {
          id: number;
          name: string;
          qty: number;
          totalPrice: number;
        }[] = [];

        listPendaftar.forEach((pendaftar) => {
          pendaftar.events.forEach((eventId) => {
            const event = mergeArray.find(
              (workshop) => workshop.id === eventId
            );

            if (event) {
              const existingSummary = priceSummary.find(
                (item) => item.id === event.id
              );

              if (existingSummary) {
                // update qty
                existingSummary.qty += 1;
                existingSummary.totalPrice += event.price;
              } else {
                // create a new summary
                priceSummary.push({
                  id: event.id,
                  name: event.name,
                  qty: 1,
                  totalPrice: event.price,
                });
              }
            }
          });
        });

        return priceSummary;
      };
      setPriceSummary(calculateSummary());
    }
  }, [mergeArray, workshopArray, listPendaftar]);

  useEffect(() => {
    if (listPendaftar.length === 0) {
      setCurrentStep(0);
    }
  }, [listPendaftar, setCurrentStep]);

  useEffect(() => {
    const calculateTotalPrice = async () => {
      setLoading(true);
      const peserta: IPeserta[] = [];

      listPendaftar.forEach((pendaftar) => {
        peserta.push({
          name: pendaftar.name,
          email: pendaftar.email,
          categoryId: pendaftar.categoryId,
          phone: pendaftar.phone,
          NPAPerdoski: pendaftar.NPAPerdoski,
          NPAIDI: pendaftar.NPAIDI,
          cabang: pendaftar.cabang,
          fileSTR: pendaftar.fileSTR,
          workshops: pendaftar.events,
          nik: pendaftar.nik,
        });
      });

      try {
        const response = await axiosInstance.post("/orders/get-price", {
          peserta,
        });
        setTotalPrice(response.data.result);
      } catch (error) {
        // console.log(error);
      } finally {
        setLoading(false);
      }
    };

    calculateTotalPrice();
  }, [listPendaftar]);

  useEffect(() => {
    const getTransactionPrice = async () => {
      try {
        const response = await axiosInstance.get(
          `/products/transaction-fee/${formData.categoryId}`
        );
        setTransactionPrice(response.data.result);
      } catch (error) {}
    };
    getTransactionPrice();
  }, [formData.categoryId]);

  const isMaxPendaftar = listPendaftar.length === 10;

  const validateEmail = (email: string) =>
    email
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;
    setEmail(newEmail);

    if (validateEmail(newEmail)) {
      setIsEmailValid(true);
    } else {
      setIsEmailValid(false);
    }
  };

  const handlePayment = async () => {
    setLoading(true);
    const peserta: IPeserta[] = [];

    listPendaftar.forEach((pendaftar) => {
      peserta.push({
        name: pendaftar.name,
        email: pendaftar.email.toLowerCase(),
        categoryId: pendaftar.categoryId,
        phone: "+62" + pendaftar.phone,
        NPAPerdoski: pendaftar.NPAPerdoski,
        NPAIDI: pendaftar.NPAIDI,
        cabang: pendaftar.cabang,
        fileSTR: pendaftar.fileSTR,
        workshops: pendaftar.events,
        nik: pendaftar.nik,
      });
    });

    const payload: IPayload = {
      email: email,
      peserta: peserta,
    };

    try {
      const response = await axiosInstance.post("/orders/create", payload);
      window.location.href = response.data.result.paymentLink;
    } catch (error) {
      // console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="static">
      <div className="flex flex-col xl:flex-row gap-4 sm:px-[66px] sm:py-[28px] gap-[28px] min-h-[80vh] items-between">
        <div className="xl:w-2/3 rounded-[32px] sm:shadow-3xl sm:p-[32px]">
          <div className="flex justify-between p-[32px] pb-0">
            <h1 className="text-base sm:text-2xl font-semibold ">
              Ringkasan Pendaftaran
            </h1>
            {/* {!isMaxPendaftar && ( */}
            <button
              disabled={isMaxPendaftar}
              onClick={() => setShowRegistrationModal(true)}
              className={`flex py-[8px] w-[156px] h-[32px] rounded-[30px] items-center justify-center bg-white border-2 ${
                isMaxPendaftar
                  ? "text-inactive border-inactive"
                  : "text-primary-100 border-primary-100 shadow-3xl"
              }`}
            >
              + Tambah Peserta
            </button>
            {/* )} */}
            {showRegistrationModal && isAnggota && (
              <AddAnggotaModal
                role={role}
                setShowModal={setShowRegistrationModal}
                formData={formData}
                setFormData={setFormData}
                isValid={isValid}
                setIsValid={setIsValid}
              />
            )}
            {showRegistrationModal && !isAnggota && (
              <AddDokterModal
                role={role}
                setShowModal={setShowRegistrationModal}
                formData={formData}
                setFormData={setFormData}
                isValid={isValid}
                setIsValid={setIsValid}
              />
            )}
          </div>
          <h2 className="py-[8px] px-[32px] text-[14px] sm:text-[18px] font-semibold text-gray-400">
            {role}
          </h2>
          <div className="flex bg-[#F3F5F7] sm:bg-white xl:grid xl:grid-cols-2 gap-x-4 gap-y-8 pt-4 pb-4 pr-2 pl-4 sm:pl-0 overflow-x-auto hide-scrollbar">
            {listPendaftar.map((anggota: IPendaftar) => (
              <div className="p-6 rounded-[20px] min-w-[328px] w-[368px] shadow-3xl m-auto bg-white">
                <ul className="space-y-3">
                  <li className="flex justify-between">
                    <span className="flex items-center">
                      <img src={personIllustration} alt="person" />
                      <span className="ml-2 font-bold">{anggota.name}</span>
                    </span>
                    <button onClick={() => handleDelete(anggota.id)}>
                      <img src={deleteIcon} alt="delete" />
                    </button>

                    <dialog id="my_modal_4" className="modal">
                      <div className="modal-box w-[400px]max-w-2xl bg-white">
                        <h3 className="font-bold text-lg">
                          Hapus pendaftaran?
                        </h3>
                        <p className="py-4">
                          Data yang kamu sudah isi akan tidak tersimpan
                        </p>
                        <div className="modal-action">
                          <form method="dialog">
                            <button className="btn bg-white text-primary-100 border-0 hover:bg-[#C1C1C1] rounded-[30px] hover:text-white mr-4">
                              Batal
                            </button>
                            <button
                              className="btn text-white bg-primary-100 border-0 rounded-[30px] p-[8px] w-[110px]"
                              onClick={handleSubmitDelete}
                            >
                              Hapus
                            </button>
                          </form>
                        </div>
                      </div>
                    </dialog>
                  </li>
                  <li>
                    <span className="text-[#828282] font-medium mr-2">
                      No Hp{" "}
                    </span>
                    +62{anggota.phone}
                  </li>
                  <li>
                    <span className="text-[#828282] font-medium mr-2">
                      Email{" "}
                    </span>
                    {anggota.email}
                  </li>
                  <li>
                    <span className="text-[#828282] font-medium mr-2">
                      No Pokok Anggota {!isAnggota && "IDI"}
                    </span>
                    {anggota.NPAPerdoski || anggota.NPAIDI}
                  </li>
                  {isAnggota && (
                    <li>
                      <span className="text-[#828282] font-medium mr-2">
                        Asal PC{" "}
                      </span>
                      {anggota.cabang}
                    </li>
                  )}
                  {!isAnggota && (
                    <li>
                      <p className="flex gap-2">
                        <span className="text-[#828282] font-medium mr-2">
                          STR{" "}
                        </span>
                        Terunggah
                        <img src={successIcon} alt="success" />
                      </p>
                    </li>
                  )}
                  <li>
                    <span className="text-[#828282] font-medium mr-2">
                      Acara{" "}
                    </span>
                    Simposium{anggota.events.includes(1) ? ", Pra-Konas" : ""}
                  </li>
                  <li>
                    <span className="text-[#828282] font-medium mr-2">
                      Workshop{" "}
                    </span>
                    {getListWorkshop(anggota.events as number[]) || "-"}
                  </li>
                </ul>
                <button
                  className="flex py-[8px] mt-8 w-full h-[32px] rounded-[30px] items-center justify-center shadow-3xl text-primary-100 bg-white border-primary-100 border-2"
                  onClick={() => {
                    setShowEditModal(true);
                    setId(anggota.id);
                  }}
                >
                  Ubah
                </button>
                {showEditModal && isAnggota && (
                  <EditAnggotaModal
                    id={id}
                    role={role}
                    setShowModal={setShowEditModal}
                    setIsValid={setIsValid}
                  />
                )}
                {showEditModal && !isAnggota && (
                  <EditDokterModal
                    id={id}
                    role={role}
                    setShowModal={setShowEditModal}
                    setIsValid={setIsValid}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="xl:w-1/3 rounded-[32px] shadow-3xl mb-28 mx-[16px] px-[32px] py-[20px] sm:m-0 sm:p-[32px] h-full flex flex-col">
          <div className="">
            <h1 className="text-base sm:text-2xl font-semibold mb-8">
              Ringkasan Pembayaran
            </h1>
            <ul className="space-y-4 sm:space-y-0">
              <li className="flex items-center justify-between">
                <span className="flex-grow text-[14px] sm:text-base text-[#828282]">
                  {listPendaftar && listPendaftar.length} tiket Simposium
                </span>
                <span className="ml-4">
                  Rp{" "}
                  {simposiumPrice &&
                    (simposiumPrice * listPendaftar.length).toLocaleString(
                      "id-ID"
                    )}
                </span>
              </li>

              {priceSummary &&
                priceSummary.map((item) => (
                  <li className="flex items-center justify-between">
                    <span className="flex-grow text-[14px] sm:text-base text-[#828282] max-w-[50%] md:max-w-[70%]">
                      {item.qty} tiket {item.name}
                    </span>
                    <span className="ml-4">
                      Rp {item.totalPrice.toLocaleString("id-ID")}
                    </span>
                  </li>
                ))}

              <li className="flex items-center">
                <span className="flex-grow text-[#828282]">
                  Biaya Transaksi
                </span>
                <span className="ml-4">
                  Rp{" "}
                  {transactionPrice && transactionPrice.toLocaleString("id-ID")}
                </span>
              </li>

              <li className="sm:flex hidden items-center justify-between py-4 mt-3 border-t border-gray-100 ">
                <span className="flex-grow text-[#828282]">
                  Total Pembayaran
                </span>
                <span className="ml-4 font-bold text-xl">
                  Rp {totalPrice && totalPrice.toLocaleString("id-ID")}
                </span>
              </li>
            </ul>
            <div className="mt-4 sm:mt-0">
              <label
                htmlFor="email"
                className="block text-base sm:text-xl font-bold"
              >
                Email<span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="email"
                name="email"
                value={email}
                onChange={handleEmailChange}
                required
                className="mt-1 py-2 px-4 border-[1px] border-[#BABDCC] rounded-md w-[90%] bg-white"
                placeholder="Masukan alamat Email anda disini"
              />
              <p className="mt-1 text-sm text-gray-500 mb-3">
                Masukan email untuk menerima konfirmasi transaksi
              </p>
            </div>
          </div>
          <div className="mt-4 hidden sm:block">
            {!isLoading ? (
              <button
                className={`flex px-[40px] py-[8px] w-[90%] m-auto rounded-[30px] items-center justify-center shadow-3xl text-white ${
                  isEmailValid ? "bg-primary-100" : "bg-disabled"
                }`}
                onClick={handlePayment}
                disabled={!isEmailValid}
              >
                Lanjut
              </button>
            ) : (
              <button
                disabled
                type="button"
                className="flex px-[40px] py-[8px] w-[90%] m-auto rounded-[30px] items-center justify-center shadow-3xl text-white bg-primary-100"
              >
                <svg
                  aria-hidden="true"
                  role="status"
                  className="inline w-4 h-4 me-3 text-white animate-spin"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#E5E7EB"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentColor"
                  />
                </svg>
                Lanjut...
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="absolute flex bottom-0 sm:hidden w-full border-t-2 bg-white py-4">
        <div className="ml-8">
          <p className="text-[#828282]">Total Pembayaran</p>
          <p className="ml-4 font-bold">
            Rp {totalPrice && totalPrice.toLocaleString("id-ID")}
          </p>
        </div>
        {!isLoading ? (
          <button
            className={`flex px-[40px] py-[8px] w-[165px] m-auto rounded-[30px] items-center justify-center shadow-3xl text-white ${
              isEmailValid ? "bg-primary-100" : "bg-disabled"
            }`}
            onClick={handlePayment}
            disabled={!isEmailValid}
          >
            Lanjut
          </button>
        ) : (
          <button
            disabled
            type="button"
            className="flex px-[40px] py-[8px] w-[165px] m-auto rounded-[30px] items-center justify-center shadow-3xl text-white bg-primary-100"
          >
            <svg
              aria-hidden="true"
              role="status"
              className="inline w-4 h-4 me-3 text-white animate-spin"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="#E5E7EB"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentColor"
              />
            </svg>
            Lanjut...
          </button>
        )}
      </div>
    </div>
  );
};

export default Summary;
