import { IPendaftar } from "../context/AppContext";
import RegistrationAnggota from "./anggota/RegistrationAnggota";
import RegistrationDokter from "./dokter/RegistrationDokter";

interface RegistrationFormProps {
  setIsValid: React.Dispatch<React.SetStateAction<boolean>>;
  role: string;
  formData: IPendaftar;
  isValid: boolean;
  setFormData: React.Dispatch<React.SetStateAction<IPendaftar>>;
  onNext: () => void;
}

const Registration = ({
  role,
  formData,
  isValid,
  setIsValid,
  setFormData,
  onNext,
}: RegistrationFormProps) => {
  return (
    <div>
      {(role === "Anggota Biasa Perdoski (Sp.KK/ Sp.DV/ Sp.DVE)" ||
        role === "Anggota Muda Perdoski (Residen)") && (
        <RegistrationAnggota
          role={role}
          formData={formData}
          setFormData={setFormData}
          isValid={isValid}
          setIsValid={setIsValid}
          onNext={onNext}
        />
      )}
      {(role === "Dokter Umum" || role === "Dokter Spesialis Lain") && (
        <RegistrationDokter
          role={role}
          formData={formData}
          setFormData={setFormData}
          isValid={isValid}
          setIsValid={setIsValid}
          onNext={onNext}
        />
      )}
    </div>
  );
};

export default Registration;
