import React from 'react';
import Lottie from 'react-lottie';
import animationSuccess from '../assets/animations/payment_success.json';
import animationFailure from '../assets/animations/payment_failure.json';
import animationPending from '../assets/animations/payment_pending.json';

interface Props {
  status: () => string;
}

const LottieAnimation = ({status}: Props) => {
  const getAnimation = () => {
    if (status() === 'success'){
      return animationSuccess;
    } else if (status() === 'pending'){
      return animationPending;
    } else {
      return animationFailure;
    }
  }
  
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: getAnimation(),
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div className="w-1/2 mx-auto">
      <Lottie options={defaultOptions} />
    </div>
  );
};

export default LottieAnimation;