import { FiChevronLeft } from "react-icons/fi";
import { IBroadcast } from "../../pages/admin-dashboard/Whatsapp";

interface OralModalProps {
  type: string;
  data: IBroadcast;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const BroadcastDetail = ({ type, data, setShowModal }: OralModalProps) => {
  const members = [
    { id: "1", name: "Anggota Biasa Perdoski" },
    { id: "2", name: "Anggota Muda Perdoski" },
    { id: "4", name: "Dokter Umum" },
    { id: "3", name: "Dokter Spesialis Lain" },
    { id: "5", name: "Testing" },
  ];
  const namesArray = data.params.recipient_types.map((id) => {
    const member = members.find((member) => member.id === id.toString());
    return member ? member.name : null;
  });

  return (
    <div>
      <div className="pointer-events-none fixed inset-0 bg-black opacity-50 z-[60] cursor-pointer" />
      <div className="fixed inset-0 flex  items-center justify-center z-[70] ">
        <div className="flex flex-col p-[30px] gap-[10px] w-[85%] sm:max-w-[976px] m-auto rounded-[24px] md:rounded-[8px] shadow-[0_0px_20px_0px_rgb(25,61,111,0.25)] bg-white max-h-[90vh]">
          <div className="flex flex-col gap-[20px] md:gap-[20px]">
            <div className="flex  items-center gap-[8px]">
              <button onClick={() => setShowModal(false)} className="">
                <FiChevronLeft className="h-[25px] w-[25px] " />
              </button>
              <h1 className="text-base sm:text-xl font-bold">
                Pesan Email Broadcast
              </h1>
            </div>
            <p className="font-semibold">
              {data.initiator} / {data.createdAt}
            </p>

            <div>
              <p className="text-gray font-bold mb-2">Penerima</p>
              {namesArray.map((member, index) => (
                <div
                  key={index}
                  className="inline-flex items-center px-3 py-1 mr-2 bg-[#FDEEEE] text-[#EB5757] rounded-[20px] w-fit"
                >
                  {member}
                </div>
              ))}
            </div>
            {type === "email" && (
              <div>
                <label
                  htmlFor="name"
                  className="block text-[14px] font-bold justify-left m-0"
                >
                  Judul Broadcast <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={data.params.subject}
                  className={`mt-1 py-2 px-4 border-[1px] rounded-md w-full bg-white border-[#BABDCC] text-[#9B9B9B]`}
                  placeholder="Masukan nama dan gelar anda disini"
                  disabled
                />
              </div>
            )}
            <div>
              <label
                htmlFor="name"
                className="block text-[14px] font-bold justify-left m-0"
              >
                Pesan <span className="text-red-500">*</span>
              </label>
              <textarea
                id="name"
                name="name"
                value={data.params.message}
                className={`mt-1 py-2 px-4 border-[1px] rounded-md w-full bg-white border-[#BABDCC] text-[#9B9B9B] min-h-[150px]`}
                placeholder="Masukan nama dan gelar anda disini"
                disabled
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BroadcastDetail;
