import { useEffect, useState } from "react";
import LayoutAdmin from "./LayoutAdmin";
import { axiosInstanceAdmin } from "../../utils/axios";
import OrderStatus from "../../components/admin/OrderStatus";
import { Pagination } from "../../components/Pagination";
import LoadingSpinner from "../../components/Loading";
import axios, { AxiosError } from "axios";
import { logoutAdmin } from "../../utils/auth";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

export interface IAbstract {
  id: string;
  status: string;
  uploaderNpa: string;
  categoryId: number;
  title: string;
  filePath: string;
  createdAt: string;
}

export interface IUpload {
  file: any;
  title: string;
  categoryId: string;
}

export interface IOrderRegistrasi {
  id: number;
  name: string;
  email: string;
  categoryId: number;
  phone: string;
  NPAPerdoski: string;
  NPAIDI: string;
  cabang: string;
  fileSTR: string;
  events: (string | number)[];
  status: string;
  orderId: number;
}

export interface IOrderRegistrasi2 {
  orderId: number;
  categoryName: string;
  name: string;
  email: string;
  status: string;
  userId: number;
  createdAt: string;
}

const RegistrationAdmin = () => {
  const [active, setActive] = useState("");
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(5);
  const [totalItem, setTotalItem] = useState(0);
  const [orders, setOrders] = useState<IOrderRegistrasi2[] | null>([]);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const next = () => {
    if (page === totalPage) return;

    setPage(page + 1);
  };

  const prev = () => {
    if (page === 1) return;

    setPage(page - 1);
  };

  useEffect(() => {
    const getOrderRegistrasi = async () => {
      try {
        setLoading(true);
        const response = await axiosInstanceAdmin.get(
          `/admin/orders?search=${search}&categoryId=${active}&page=${page}`
        );
        setOrders(response.data.result.orders);
        setTotalPage(response.data.result.totalPages);
        setTotalItem(response.data.result.total);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const axiosError = error as AxiosError;
          if (axiosError.response && axiosError.response.status === 401) {
            enqueueSnackbar("Mohon log in kembali", {
              autoHideDuration: 3000,
              variant: "error",
              anchorOrigin: { vertical: "top", horizontal: "right" },
            });
            navigate("/login-admin");
            logoutAdmin();
          }
        }
      } finally {
        setLoading(false);
      }
    };

    const delay = 1000;

    const timerId = setTimeout(() => {
      getOrderRegistrasi();
    }, delay);

    return () => clearTimeout(timerId);
  }, [active, search, page]);

  useEffect(() => {
    setPage(1);
  }, [active]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value;
    setSearch(searchValue);
  };

  return (
    <LayoutAdmin>
      <div className="flex flex-col gap-[16px]">
        <div className="w-full bg-white rounded-[16px] p-[24px] font-bold text-[32px]">
          <h1>Order Registrasi</h1>
        </div>
        <div className="flex flex-col w-full bg-white rounded-[16px] p-[24px] gap-[16px]">
          <h1 className="font-bold text-2xl">Kategori Peserta</h1>
          <div className="flex gap-[12px]">
            <button
              onClick={() => setActive("")}
              className={`flex px-[12px] py-[6px] rounded-[20px] items-center justify-center ${
                active === ""
                  ? "text-white bg-primary-100"
                  : "text-[#AFAFAF] bg-[#EBEBEB]"
              }`}
            >
              Semua
            </button>
            <button
              onClick={() => setActive("1")}
              className={`flex px-[12px] py-[6px] rounded-[20px] items-center justify-center ${
                active === "1"
                  ? "text-white bg-primary-100"
                  : "text-[#AFAFAF] bg-[#EBEBEB]"
              }`}
            >
              Anggota Biasa Perdoski
            </button>
            <button
              onClick={() => setActive("2")}
              className={`flex px-[12px] py-[6px] rounded-[20px] items-center justify-center ${
                active === "2"
                  ? "text-white bg-primary-100"
                  : "text-[#AFAFAF] bg-[#EBEBEB]"
              }`}
            >
              Anggota Muda Perdoski
            </button>
            <button
              onClick={() => setActive("4")}
              className={`flex px-[12px] py-[6px] rounded-[20px] items-center justify-center ${
                active === "4"
                  ? "text-white bg-primary-100"
                  : "text-[#AFAFAF] bg-[#EBEBEB]"
              }`}
            >
              Dokter Umum
            </button>
            <button
              onClick={() => setActive("3")}
              className={`flex px-[12px] py-[6px] rounded-[20px] items-center justify-center ${
                active === "3"
                  ? "text-white bg-primary-100"
                  : "text-[#AFAFAF] bg-[#EBEBEB]"
              }`}
            >
              Dokter Spesialis Lain
            </button>
          </div>
          <input
            type="text"
            id="search"
            name="search"
            value={search}
            onChange={handleSearchChange}
            className={`mt-1 py-2 px-4 rounded-2xl w-full md:w-[616px] bg-[#EBEBEB] border-[#BABDCC] text-gray-500`}
            placeholder="Cari Peserta"
          />
        </div>

        <div className="flex flex-col bg-white rounded-[16px] p-[24px] items-center gap-[16px]">
          <table className="min-w-full">
            <thead className="">
              <tr>
                <th scope="col" className="">
                  Order ID
                </th>
                <th scope="col" className="">
                  Kategori Peserta
                </th>
                <th scope="col" className="">
                  Nama Peserta
                </th>
                <th scope="col" className="">
                  Email Peserta
                </th>
                <th scope="col" className="">
                  Tanggal Registrasi
                </th>
                <th scope="col" className="">
                  Status Order
                </th>
                <th scope="col" className="">
                  Formulir
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {isLoading && (
                <tr>
                  <td colSpan={7} className="text-center py-6">
                    <LoadingSpinner />
                  </td>
                </tr>
              )}
              {!isLoading &&
                orders &&
                orders.map((order) => (
                  <OrderStatus data={order} key={order.userId} />
                ))}
            </tbody>
          </table>
        </div>
        <div className="flex justify-between px-4">
          <div>
            <span className="font-bold">{totalItem}</span> results
          </div>
          <Pagination
            page={page}
            totalPage={totalPage}
            next={next}
            prev={prev}
          />
        </div>
      </div>
    </LayoutAdmin>
  );
};

export default RegistrationAdmin;
