import { IPesertaAdmin } from "../../pages/admin-dashboard/DaftarPeserta";
import { useEffect, useState } from "react";
import { IPendaftar } from "../../context/AppContext";
import AnggotaViewForm from "./AnggotaViewForm";
import DokterViewForm from "./DokterViewForm";
import { FiChevronLeft } from "react-icons/fi";
import { axiosInstanceAdmin } from "../../utils/axios";

interface OrderStatusProps {
  data: IPesertaAdmin;
}

const RegistrasiStatus = ({ data }: OrderStatusProps) => {
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [id, setId] = useState(data.id);

  const getStyle = (status: string) => {
    if (status === "PENDING") {
      return "bg-[#FDF5EE] text-[#F2994A]";
    } else if (status === "CONFIRMED") {
      return "bg-[#E6F4EE] text-[#008E58]";
    } else {
      return "bg-[#FDEEEE] text-[#EB5757]";
    }
  };

  useEffect(() => {
    setId(data.id);
  }, [data]);

  const [formData, setFormData] = useState<IPendaftar>({
    id: 0,
    name: "",
    email: "",
    categoryId: 1,
    phone: "",
    NPAPerdoski: "",
    NPAIDI: "",
    cabang: "",
    fileSTR: "",
    events: [],
    nik: "",
  });

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axiosInstanceAdmin.get(`/admin/users/${id}`);
        setFormData({
          ...response.data.result,
          events: response.data.result.workshops,
        });
      } catch (error) {
        // console.log(error);
      } finally {
      }
    };
    fetchUserData();
  }, [id]);

  return (
    <tr className="">
      <td className="px-6 py-4 whitespace-nowrap text-center max-w-[120px] text-ellipsis overflow-x-hidden">
        {data.id}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-center max-w-[180px] text-ellipsis overflow-x-hidden">
        {data.category}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-center max-w-[200px] text-ellipsis overflow-x-hidden">
        {data.name}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-center max-w-[200px] text-ellipsis overflow-x-hidden">
        {data.email}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-center max-w-[150px] text-ellipsis overflow-x-hidden">
        {data.createdAt}
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="w-[100px] m-auto">
          <span
            className={`px-[10px] py-[4px] rounded-[30px] ${getStyle(
              data.status
            )}
              `}
          >
            {data.status}
          </span>
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-inactive">
        <button onClick={() => setShowUploadModal(true)}>Lihat</button>
      </td>
      {showUploadModal && (
        <div>
          <div className="pointer-events-none fixed inset-0 bg-black opacity-50 z-[60] cursor-pointer" />
          <div className="fixed inset-0 flex items-center justify-center z-[70] ">
            <div className="flex flex-col h-full sm:w-[85%] sm:max-w-[976px] sm:rounded-[24px] md:rounded-[8px] shadow-[0_0px_20px_0px_rgb(25,61,111,0.25)] bg-white sm:overflow-y-auto sm:max-h-[80vh]">
              <div className="p-[24px] flex border-b border-gray-100">
                <div className="flex justify-center items-center gap-[8px]">
                  <button
                    onClick={() => setShowUploadModal(false)}
                    className=""
                  >
                    <FiChevronLeft className="h-[25px] w-[25px] " />
                  </button>
                  <h1 className="text-base sm:text-xl font-bold">
                    Detail Peserta
                  </h1>
                </div>
              </div>
              <form className="flex flex-col gap-[40px] overflow-y-auto p-8 ">
                {data.category ===
                  "Anggota Biasa Perdoski (Sp.KK/ Sp.DV/ Sp.DVE)" ||
                data.category === "Anggota Muda Perdoski" ? (
                  <AnggotaViewForm formData={formData} isPrice={true} />
                ) : (
                  <DokterViewForm formData={formData} isPrice={true} />
                )}
              </form>
            </div>
          </div>
        </div>
      )}
    </tr>
  );
};

export default RegistrasiStatus;
