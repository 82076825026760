import axios from "axios";
import { config } from "./config";

const BACKEND_REST_API_URL = config.api.backendUrl;

const axiosInstance = axios.create({
  baseURL: BACKEND_REST_API_URL,
  headers: {
    Authorization: ``,
    "Access-Control-Allow-Origin": "*",
  },
});

const axiosInstanceUser = axios.create({
  baseURL: BACKEND_REST_API_URL,
  headers: {
    Authorization: ``,
    "Access-Control-Allow-Origin": "*",
  },
});

const axiosInstanceAdmin = axios.create({
  baseURL: BACKEND_REST_API_URL,
  headers: {
    Authorization: ``,
    "Access-Control-Allow-Origin": "*",
  },
});

axiosInstanceAdmin.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("tokenAdmin");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstanceUser.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("tokenUser");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export { axiosInstance, axiosInstanceAdmin, axiosInstanceUser };
