import { jwtDecode } from "jwt-decode";

type Payload = {
  aud: string;
  email: string;
  email_verified: boolean;
  exp: number;
  iat: number;
  iss: string;
  name: string;
  nickname: string;
  picture: string;
  role: string;
  sub: string;
  updated_at: string;
};

function decodeToken(token: string) {
  return jwtDecode(token) as Payload;
}

function setAuthTokenUser(token: string) {
  localStorage.setItem("tokenUser", token);
}

function setAuthTokenAdmin(token: string) {
  localStorage.setItem("tokenAdmin", token);
}

function decodeJwtUser() {
  const decoded: Payload = jwtDecode(
    localStorage.getItem("tokenUser") as string
  );
  return decoded;
}

function decodeJwtAdmin() {
  const decoded: Payload = jwtDecode(
    localStorage.getItem("tokenAdmin") as string
  );
  return decoded;
}

function logoutUser() {
  localStorage.removeItem("tokenUser");
}

function logoutAdmin() {
  localStorage.removeItem("tokenAdmin");
}

function isAuthenticatedUser() {
  return localStorage.getItem("tokenUser") !== null;
}

function isAuthenticatedAdmin() {
  return localStorage.getItem("tokenAdmin") !== null;
}

export {
  setAuthTokenAdmin,
  setAuthTokenUser,
  decodeJwtAdmin,
  decodeJwtUser,
  logoutUser,
  logoutAdmin,
  isAuthenticatedUser,
  isAuthenticatedAdmin,
  decodeToken,
};
