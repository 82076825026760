import {
  PendaftarContext,
  PendaftarContextType,
  IPendaftar,
} from "../../context/AppContext";
import { useEffect, useState, useContext } from "react";
import axios, { AxiosError } from "axios";
import { axiosInstance } from "../../utils/axios";
import InfoIcon from "../InfoIcon";
import { cabang } from "../../assets/data/cabang";

interface RegistrationFormProps {
  role: string;
  formData: IPendaftar;
  isPrice: boolean;
  setFormData: React.Dispatch<React.SetStateAction<IPendaftar>>;
  setIsValid: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ErrorData {
  success: boolean;
  result: any;
  error: string;
}

interface IWorkshop {
  id: number;
  name: string;
  price: number;
}

const AnggotaForm = ({
  role,
  formData,
  setFormData,
  isPrice,
  setIsValid,
}: RegistrationFormProps) => {
  const [simposiumPrice, setSimposiumPrice] = useState(0);
  const [workshops, setWorkshops] = useState<IWorkshop[]>([]);
  const [pendamping, setPendamping] = useState<IWorkshop[]>([]);
  const [acara, setAcara] = useState<IWorkshop[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [workshopIds, setWorkshopIds] = useState<(number | string)[]>([]);
  const { listPendaftar } = useContext(
    PendaftarContext
  ) as PendaftarContextType;

  useEffect(() => {
    const getWorkshopsData = async () => {
      try {
        if (formData.categoryId === 1 || formData.categoryId === 2) {
          const response = await axiosInstance.get(
            `/products/${formData.categoryId}`
          );
          setWorkshops(response.data.result.workshops);
          setAcara(response.data.result.acara);
          setPendamping(response.data.result.pendamping);
          setSimposiumPrice(response.data.result.basePrice);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    getWorkshopsData();
  }, [formData.categoryId]);

  const [errorMsgNPA, setErrorMsgNPA] = useState("");
  const [errorMessageEmail, setErrorMessageEmail] = useState("");
  const [errorMessagePhone, setErrorMessagePhone] = useState("");
  const [errorMessageNIK, setErrorMessageNIK] = useState("");

  const [errors, setErrors] = useState({
    name: false,
    phone: false,
    email: false,
    cabang: false,
    NPAPerdoski: false,
    nik: false,
  });

  const isAllNumbers = (input: string) => {
    return /^[0-9]+$/.test(input);
  };

  const validateInput = (name: string, value: any) => {
    const newErrors = { ...errors };

    if (name === "name") {
      newErrors.name = value === "";
    }

    if (name === "phone") {
      newErrors.phone =
        value === "" ||
        !isAllNumbers(value) ||
        value[0] === "0" ||
        (value[0] === "6" && value[1] === "2");
      if (value === "") {
        setErrorMessagePhone("Nomor telepon tidak boleh kosong");
      } else if (!isAllNumbers(value)) {
        setErrorMessagePhone(
          "Mohon masukkan nomor tanpa karakter lain seperti -"
        );
      } else if (value[0] === "0") {
        setErrorMessagePhone("No telepon tidak boleh diawali 0");
      } else if (value[0] === "6" && value[1] === "2") {
        setErrorMessagePhone("No telepon tidak boleh diawali 62");
      }
    }

    if (name === "email") {
      setIsLoading(true);
      newErrors.email =
        value === "" ||
        !value
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      if (newErrors.email) {
        setErrorMessageEmail("Email tidak valid");
      }
    }

    if (name === "cabang") {
      newErrors.cabang = value === "";
    }

    if (name === "NPAPerdoski") {
      setIsLoading(true);
      newErrors.NPAPerdoski = value === "";
    }

    if (name === "nik") {
      newErrors.nik = value.length !== 16 || !/^\d+$/.test(value);

      if (value.length !== 16) {
        setErrorMessageNIK("NIK harus terdiri atas 16 digit");
      } else if (!/^\d+$/.test(value)) {
        setErrorMessageNIK("NIK harus berupa angka");
      }
    }

    setErrors(newErrors);
  };

  const checkNotNull = () => {
    return (
      formData.name.length > 0 &&
      formData.phone.length > 0 &&
      formData.email.length > 0 &&
      formData.cabang.length > 0 &&
      formData.NPAPerdoski.length > 0
    );
  };

  useEffect(() => {
    if (
      !(
        errors.name ||
        errors.phone ||
        errors.NPAPerdoski ||
        errors.email ||
        errors.cabang
      ) &&
      checkNotNull() &&
      !isLoading
    ) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [formData, errors]);

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    validateInput(name, value);
  };

  const handleNPAChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const trimmedValue = e.target.value.replace(/\s/g, "");
    setFormData({ ...formData, NPAPerdoski: trimmedValue });
  };

  useEffect(() => {
    if (workshops.length > 0) {
      const newArray: number[] = [];

      workshops.forEach((item) => {
        newArray.push(item.id);
      });

      setWorkshopIds(newArray);
    }
  }, [workshops]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    const eventId = parseInt(name, 10);

    setFormData((prevFormData) => {
      if (checked) {
        return {
          ...prevFormData,
          events: [...prevFormData.events, eventId],
        };
      } else {
        return {
          ...prevFormData,
          events: prevFormData.events.filter((id) => id !== eventId),
        };
      }
    });
  };

  const handleWorkshopCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, checked } = event.target;
    const eventId = parseInt(name, 10);

    setFormData((prevFormData) => {
      if (checked) {
        const resultArray = prevFormData.events.filter(
          (item) => !workshopIds.includes(item)
        );
        return {
          ...prevFormData,
          events: [...resultArray, eventId],
        };
      } else {
        return {
          ...prevFormData,
          events: prevFormData.events.filter((id) => id !== eventId),
        };
      }
    });
  };

  const checkNPAInDatabase = async () => {
    try {
      if (formData.NPAPerdoski) {
        const response = await axiosInstance.get(
          `/orders/validate/${formData.NPAPerdoski}`
        );
        setErrors({ ...errors, NPAPerdoski: false });
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          const responseData = axiosError.response.data as ErrorData;
          setErrorMsgNPA(responseData.error);
          setErrors({ ...errors, NPAPerdoski: true });
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const isNPAExists = () => {
    return listPendaftar.some(
      (user) =>
        user.NPAPerdoski === formData.NPAPerdoski && user.id !== formData.id
    );
  };
  const checkNPAInListPendaftar = () => {
    if (isNPAExists()) {
      setErrors({ ...errors, NPAPerdoski: true });
      setErrorMsgNPA("NPA sudah didaftarkan sebelumnya");
    }
  };

  const checkEmailInDatabase = async () => {
    try {
      if (formData.email) {
        const response = await axiosInstance.get(
          `/orders/validate-email?email=${formData.email}`
        );
        setErrors({ ...errors, email: false });
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          const responseData = axiosError.response.data as ErrorData;
          setErrorMessageEmail(responseData.error);
          setErrors({ ...errors, email: true });
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const isEmailExists = () => {
    return listPendaftar.some(
      (user) => user.email === formData.email && user.id !== formData.id
    );
  };
  const checkEmailInListPendaftar = () => {
    if (isEmailExists()) {
      setErrors({ ...errors, email: true });
      setErrorMessageEmail("Email sudah didaftarkan sebelumnya");
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      checkNPAInListPendaftar();
      if (!isNPAExists()) {
        checkNPAInDatabase();
      }
    }, 1000);

    return () => clearTimeout(timeoutId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.NPAPerdoski]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      checkEmailInListPendaftar();
      if (!isEmailExists() && !errors.email) {
        checkEmailInDatabase();
      }
    }, 1000);

    return () => clearTimeout(timeoutId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.email]);

  return (
    <div className="flex flex-col gap-[28px]">
      <div className="flex flex-col gap-[20px] md:gap-[40px]">
        <div>
          <label
            htmlFor="name"
            className="block text-[12px] sm:text-xl font-bold"
          >
            Nama dan Gelar <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className={`mt-1 py-2 px-4 border-[1px] rounded-md w-full md:w-[616px] bg-white ${
              errors.name
                ? "border-red-500 focus:ring-0 focus:outline-none focus:border-red-500"
                : "border-[#BABDCC]"
            }`}
            placeholder="Masukan nama dan gelar anda di sini"
          />
          <p
            className={`mt-1 text-sm ${
              errors.name ? "text-red-500" : "text-gray-500"
            }`}
          >
            {" "}
            {errors.name
              ? "Nama dan gelar tidak boleh kosong"
              : "Masukan nama yang ingin dicantumkan dalam sertifikat"}
          </p>
        </div>

        <div>
          <label
            htmlFor="lastName"
            className="block text-[12px] sm:text-xl font-bold"
          >
            No telepon<span className="text-red-500">*</span>
          </label>
          +62
          <input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            className={`ml-2 mt-1 py-2 px-4 border-[1px] rounded-md w-full md:w-[580px] bg-white ${
              errors.phone
                ? "border-red-500 focus:ring-0 focus:outline-none focus:border-red-500"
                : "border-[#BABDCC]"
            }`}
            placeholder="Masukan No Telepon anda di sini "
          />
          <p
            className={`mt-1 text-sm ${
              errors.phone ? "text-red-500" : "text-gray-500"
            }`}
          >
            {errors.phone
              ? errorMessagePhone
              : "Masukan nomor anda yang terdaftar di aplikasi WhatsApp"}
          </p>
        </div>

        <div>
          <label
            htmlFor="email"
            className="block text-[12px] sm:text-xl font-bold"
          >
            Email<span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            className={`mt-1 py-2 px-4 border-[1px] border-[#BABDCC] rounded-md w-full md:w-[616px] bg-white ${
              errors.email
                ? "border-red-500 focus:ring-0 focus:outline-none focus:border-red-500"
                : "border-[#BABDCC]"
            }`}
            placeholder="Masukan alamat Email anda di sini"
          />
          <p
            className={`mt-1 text-sm ${
              errors.email ? "text-red-500" : "text-gray-500"
            }`}
          >
            {errors.email ? errorMessageEmail : ""}
          </p>
        </div>

        <div>
          <label
            htmlFor="nik"
            className="block text-[12px] sm:text-xl font-bold"
          >
            NIK<span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            id="nik"
            name="nik"
            value={formData.nik}
            onChange={handleChange}
            required
            className={`mt-1 py-2 px-4 border-[1px] border-[#BABDCC] rounded-md w-full md:w-[616px] bg-white ${
              errors.nik
                ? "border-red-500 focus:ring-0 focus:outline-none focus:border-red-500"
                : "border-[#BABDCC]"
            }`}
            placeholder="Masukan NIK anda di sini"
          />
          <p
            className={`mt-1 text-sm ${
              errors.nik ? "text-red-500" : "text-gray-500"
            }`}
          >
            {errors.nik ? errorMessageNIK : ""}
          </p>
        </div>

        <div>
          <label
            htmlFor="cabang"
            className="block text-[12px] sm:text-xl font-bold"
          >
            Asal PC (Cabang) <span className="text-red-500">*</span>
          </label>
          <select
            id="cabang"
            name="cabang"
            value={formData.cabang}
            onChange={handleChange}
            required
            className={`mt-1 py-2 px-4 border-[1px] border-[#BABDCC] rounded-md w-full md:w-[616px] bg-white ${
              errors.cabang
                ? "border-red-500 focus:ring-0 focus:outline-none focus:border-red-500"
                : "border-[#BABDCC]"
            }`}
          >
            <option value="" className="text-[#AFAFAF]" disabled>
              Pilih Cabang
            </option>
            {cabang.map((kota) => (
              <option value={kota}>{kota}</option>
            ))}
          </select>
          <p
            className={`mt-1 text-sm ${
              errors.cabang ? "text-red-500" : "text-gray-500"
            }`}
          >
            {errors.cabang ? "Cabang tidak boleh kosong" : ""}
          </p>
        </div>
        <div>
          <label
            htmlFor="noAnggota"
            className="block text-[12px] sm:text-xl font-bold"
          >
            Nomor Pokok Anggota Perdoski<span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            id="NPAPerdoski"
            name="NPAPerdoski"
            value={formData.NPAPerdoski}
            onChange={handleNPAChange}
            required
            className={`mt-1 py-2 px-4 border-[1px] rounded-md w-full md:w-[616px] bg-white ${
              errors.NPAPerdoski
                ? "border-red-500 focus:ring-0 focus:outline-none focus:border-red-500"
                : "border-[#BABDCC]"
            }`}
            placeholder="Masukan nomor pokok anggota anda di sini"
          />

          <p
            className={`mt-1 text-sm ${
              errors.NPAPerdoski ? "text-red-500" : "text-gray-500"
            }`}
          >
            {errors.NPAPerdoski ? errorMsgNPA : ""}
          </p>
        </div>

        <div>
          <div className="flex gap-[10px]">
            <label
              htmlFor="acara"
              className="block text-[12px] sm:text-xl font-bold"
            >
              Acara yang ingin diikuti
            </label>
            <InfoIcon />
          </div>
          <div className="flex flex-col md:flex-row gap-4 md:gap-0">
            <div className="pr-12">
              <label className="container">
                {" "}
                <span className="text-[#9B9B9B]">Simposium</span>
                <input
                  type="checkbox"
                  name="simposium"
                  checked={true}
                  disabled={true}
                />
                <span className="checkmark checkmark-disabled"></span>
              </label>
              {isPrice && (
                <p className="font-semibold text-[14px] text-[#C1C1C1]">
                  <span className="text-[#DC989E]">
                    Rp.
                    {simposiumPrice && simposiumPrice.toLocaleString("id-ID")}
                  </span>{" "}
                  per tiket
                </p>
              )}
            </div>
            {acara &&
              acara.map((acara) => (
                <div>
                  <label className="container">
                    {" "}
                    Pra-Konas
                    <input
                      type="checkbox"
                      name={acara.id.toString()}
                      checked={formData.events.includes(acara.id)}
                      onChange={handleCheckboxChange}
                    />
                    <span className="checkmark"></span>
                  </label>
                  {isPrice && (
                    <p className="font-semibold text-[14px]">
                      <span className="text-primary-100">
                        Rp.{acara.price.toLocaleString("id-ID")}
                      </span>{" "}
                      per tiket
                    </p>
                  )}
                </div>
              ))}
          </div>
        </div>

        <div>
          <label
            htmlFor="workshop"
            className="block text-[12px] sm:text-xl font-bold"
          >
            Workshop
          </label>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 max-w-[920px]">
            {workshops &&
              workshops.map((workshop) => (
                <div className="pr-12">
                  <label className="container">
                    {workshop.name}
                    <input
                      type="checkbox"
                      name={workshop.id.toString()}
                      checked={formData.events.includes(workshop.id)}
                      onChange={handleWorkshopCheckboxChange}
                    />
                    <span className="checkmark"></span>
                  </label>
                  {isPrice && (
                    <p className="font-semibold text-[14px]">
                      <span className="text-primary-100">
                        Rp.{workshop.price.toLocaleString("id-ID")}
                      </span>{" "}
                      per tiket
                    </p>
                  )}
                </div>
              ))}
          </div>
        </div>

        <div>
          <label
            htmlFor="pendamping"
            className="block text-[12px] sm:text-xl font-bold"
          >
            Pendamping
          </label>
          <div className="grid grid-cols-1 lg:grid-cols-4 gap-4 max-w-[920px]">
            {pendamping &&
              pendamping.map((pendamping) => (
                <div className="pr-12">
                  <label className="container">
                    {pendamping.name}
                    <input
                      type="checkbox"
                      name={pendamping.id.toString()}
                      checked={formData.events.includes(pendamping.id)}
                      onChange={handleCheckboxChange}
                    />
                    <span className="checkmark"></span>
                  </label>
                  {isPrice && (
                    <p className="font-semibold text-[14px]">
                      <span className="text-primary-100">
                        Rp.{pendamping.price.toLocaleString("id-ID")}
                      </span>{" "}
                      per tiket
                    </p>
                  )}
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnggotaForm;
