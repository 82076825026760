import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../assets/images/konas_logo2.svg";
import abstractLogoRed from "../assets/images/abstract_logo_red.svg";
import abstractLogoInactive from "../assets/images/abstract_logo_inactive.svg";
import makalahLogoRed from "../assets/images/makalah_logo_red.svg";
import makalahLogoInactive from "../assets/images/makalah_logo_inactive.svg";
import posterLogoRed from "../assets/images/poster_logo_red.svg";
import posterLogoInactive from "../assets/images/poster_logo_inactive.svg";
import kartuLogoRed from "../assets/images/kartu_logo_red.svg";
import kartuLogoInactive from "../assets/images/kartu_logo_inactive.svg";
import sertifikatLogoRed from "../assets/images/sertifikat_logo_red.svg";
import sertifikatLogoInactive from "../assets/images/sertifikat_logo_inactive.svg";
import akunLogoRed from "../assets/images/user_logo_red.svg";
import akunLogoInactive from "../assets/images/user_logo_inactive.svg";
import { FaLock } from "react-icons/fa";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { logoutUser } from "../utils/auth";
import { useSnackbar } from "notistack";
import { IoClose } from "react-icons/io5";

interface LayoutProps {
  children: React.ReactNode;
}

interface SidebarProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Sidebar = ({ isOpen, setIsOpen }: SidebarProps) => {
  const location = useLocation();
  const [isClicked, setIsClicked] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const handleLogOut = () => {
    logoutUser();
    enqueueSnackbar("Berhasil keluar dari akun", {
      autoHideDuration: 3000,
      variant: "warning",
      anchorOrigin: { vertical: "top", horizontal: "right" },
    });
    navigate("/login");
  };

  return (
    <div
      className={`w-screen sm:w-64 bg-white p-[25px] sidebar ${
        isOpen ? "block" : "hidden md:block"
      }`}
    >
      <div className="flex">
        <button onClick={() => setIsOpen(!isOpen)}>
          <IoClose className="w-10 h-10 bg-inactive sm:hidden" />
        </button>
        <img src={logo} alt="logo" className="m-auto my-8" />
      </div>
      <ul className="space-y-2">
        <li
          className={`p-4 rounded-r-[24px] ${
            location.pathname === "/abstract" && !isClicked
              ? "bg-primary-10 text-primary-100 font-extrabold hover:bg-primary-10 border-l-[10px] border-l-primary-100"
              : "text-[#828282] font-semibold hover:bg-[#EBEBEB]"
          }`}
        >
          <Link
            to="/abstract"
            className="flex flex-row gap-[12px]"
            onClick={() => setIsClicked(false)}
          >
            <img
              src={
                location.pathname === "/abstract" && !isClicked
                  ? abstractLogoRed
                  : abstractLogoInactive
              }
              alt="logo"
              className="w-[24px]"
            />
            <p>Abstract</p>
          </Link>
        </li>
        <li
          className={`p-4 rounded-r-[24px] ${
            location.pathname === "/oral" && !isClicked
              ? "bg-primary-10 text-primary-100 font-extrabold hover:bg-primary-10 border-l-[10px] border-l-primary-100"
              : "text-[#828282] font-semibold hover:bg-[#EBEBEB]"
          }`}
        >
          <Link
            to="/oral"
            className="flex flex-row justify-between items-center"
          >
            <div className="flex flex-row gap-[12px]">
              <img
                src={
                  location.pathname === "/oral"
                    ? makalahLogoRed
                    : makalahLogoInactive
                }
                alt="logo"
                className="w-[24px]"
              />
              <p>Presentasi Oral</p>
            </div>
          </Link>
        </li>
        <li
          className={`p-4 rounded-r-[24px] ${
            location.pathname === "/poster" && !isClicked
              ? "bg-primary-10 text-primary-100 font-extrabold hover:bg-primary-10 border-l-[10px] border-l-primary-100"
              : "text-[#828282] font-semibold hover:bg-[#EBEBEB]"
          }`}
        >
          <Link
            to="/poster"
            className="flex flex-row justify-between items-center"
          >
            <div className="flex flex-row gap-[12px]">
              <img
                src={
                  location.pathname === "/poster"
                    ? posterLogoRed
                    : posterLogoInactive
                }
                alt="logo"
                className="w-[24px]"
              />
              <p>Poster</p>
            </div>
          </Link>
        </li>
        <li
          className={`p-4 rounded-r-[24px] ${
            location.pathname === "/kartu-peserta" && !isClicked
              ? "bg-primary-10 text-primary-100 font-extrabold hover:bg-primary-10 border-l-[10px] border-l-primary-100"
              : "text-[#828282] font-semibold hover:bg-[#EBEBEB]"
          }`}
        >
          <Link
            to="/kartu-peserta"
            className="flex flex-row justify-between items-center"
          >
            <div className="flex flex-row gap-[12px]">
              <img
                src={
                  location.pathname === "/kartu-peserta"
                    ? kartuLogoRed
                    : kartuLogoInactive
                }
                alt="logo"
                className="w-[24px]"
              />
              <p>Kartu Peserta</p>
            </div>
          </Link>
        </li>
        <li
          className={`p-4 rounded-r-[24px] ${
            location.pathname === "/sertifikat" && !isClicked
              ? "bg-primary-10 text-primary-100 font-extrabold hover:bg-primary-10 border-l-[10px] border-l-primary-100"
              : "text-[#828282] font-semibold hover:bg-[#EBEBEB]"
          }`}
        >
          <Link
            to="/sertifikat"
            className="flex flex-row justify-between items-center"
          >
            <div className="flex flex-row gap-[12px]">
              <img
                src={
                  location.pathname === "/sertifikat"
                    ? sertifikatLogoRed
                    : sertifikatLogoInactive
                }
                alt="logo"
                className="w-[24px]"
              />
              <p>Sertifikat</p>
            </div>
            {/* <FaLock size={14} /> */}
          </Link>
        </li>
        <li
          className={`p-4 rounded-r-[24px] ${
            isClicked
              ? "bg-primary-10 text-primary-100 font-extrabold hover:bg-primary-10 border-l-[10px] border-l-primary-100"
              : "text-[#828282] font-semibold hover:bg-[#EBEBEB]"
          }`}
        >
          <button
            onClick={() => setIsClicked(!isClicked)}
            className="flex flex-row w-full justify-between items-center"
          >
            <div className="flex flex-row gap-[12px]">
              <img
                src={isClicked ? akunLogoRed : akunLogoInactive}
                alt="logo"
                className="w-[24px]"
              />
              <p>Akun</p>
            </div>
            <div className="text-[24px]">
              {isClicked ? <RiArrowDropUpLine /> : <RiArrowDropDownLine />}
            </div>
          </button>
        </li>
        {isClicked && (
          <li className={`p-4 rounded-r-[24px]  text-[#828282] font-semibold}`}>
            <button onClick={handleLogOut} className="flex flex-row gap-[12px]">
              <p>Log Out</p>
            </button>
          </li>
        )}
      </ul>
    </div>
  );
};

const Layout = ({ children }: LayoutProps) => {
  const [isSidebarOpen, setSidebarOpen] = useState<boolean>(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="flex h-screen bg-[#F7F7F7]">
      <Sidebar isOpen={isSidebarOpen} setIsOpen={setSidebarOpen} />
      <div className="flex-1 flex flex-col overflow-hidden">
        {/* Navbar */}
        <div
          className={`bg-white p-4 md:hidden ${
            isSidebarOpen ? "hidden" : "block"
          }`}
        >
          <button className="text-inactive" onClick={toggleSidebar}>
            ☰
          </button>
        </div>
        <div
          className={`flex-1 p-[24px] overflow-y-scroll ${
            isSidebarOpen ? "hidden sm:block" : ""
          }`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default Layout;
