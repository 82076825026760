import Layout from "../Layout";
import information from "../../assets/images/info.svg";
import { useEffect, useState } from "react";
import { axiosInstanceUser, axiosInstance } from "../../utils/axios";
import illustration from "../../assets/images/no_abstract_illustration.svg";
import UploadOralModal from "../../components/oral/UploadOralModal";
import OralStatus from "../../components/oral/OralStatus";
import LoadingSpinner from "../../components/Loading";
import { parseISO } from "date-fns";

const Oral = () => {
  const [name, setName] = useState("");
  const [isOral, setIsOral] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [oralFile, setOralFile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isVisible, setIsVisible] = useState(true);
  const [hideTimestamp, setHideTimestamp] = useState<Date | null>(null);

  useEffect(() => {
    const getDeadlineTime = async () => {
      try {
        const response = await axiosInstance.get("/config?key=deadline-oral");
        setHideTimestamp(parseISO(response.data.result.value));
      } catch (error) {}
    };
    getDeadlineTime();
  }, []);

  useEffect(() => {
    if (hideTimestamp) {
      const intervalId = setInterval(() => {
        const currentTime = new Date();

        if (currentTime > hideTimestamp) {
          setIsVisible(false);
          setShowUploadModal(false);
          clearInterval(intervalId);
        }
      }, 1000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [hideTimestamp]);

  const convertTime = (time: Date) => {
    let options = {
      year: "numeric" as const,
      month: "long" as const,
      day: "numeric" as const,
      hour: "numeric" as const,
      minute: "numeric" as const,
      timeZone: "Asia/Singapore",
    };
    return time.toLocaleDateString("ID-id", options);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userResponse = await axiosInstanceUser.get("/auth/profile");

        if (userResponse.status === 200) {
          setName(userResponse.data.result.data.name);
        } else {
          console.error("Error fetching user data");
          return;
        }

        const otherResponse = await axiosInstanceUser.get("/upload/check/oral");
        if (otherResponse.status === 200) {
          setIsOral(otherResponse.data.result.ableToUpload);
        } else {
          console.error("Error fetching other data");
          return;
        }
      } catch (error) {
        console.error("Error fetching data", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    const getUserAbstract = async () => {
      try {
        const response = await axiosInstanceUser.get("/abstracts/user/oral");
        setOralFile(response.data.result);
      } catch (error) {}
    };
    getUserAbstract();
  }, [showUploadModal]);

  const handleUpload = () => {
    setShowUploadModal(true);
  };

  return (
    <Layout>
      <div className="flex flex-col gap-[16px]">
        <div className="w-full bg-white rounded-[16px] p-[24px] font-bold md:text-[32px]">
          <h1>Hello, {name} 👋🏻</h1>
        </div>
        <div className="flex flex-col w-full bg-white rounded-[16px] p-[24px] gap-[16px]">
          <h1 className="font-bold md:text-2xl">Presentasi Oral</h1>
        </div>

        <div className="flex flex-col bg-white rounded-[16px] p-[24px] items-center gap-[16px]">
          {!isLoading && oralFile && <OralStatus oral={oralFile} name={name} />}
          {isVisible && isOral && !oralFile && !isLoading && (
            <div className="flex flex-col w-full items-center">
              <p className="font-bold text-[28px]">
                Belum ada Presentasi Oral yang diunggah
              </p>
              <img src={illustration} alt="illustration"></img>
              <button
                onClick={handleUpload}
                className="flex py-[8px] mt-8 h-[40px] px-[36px] rounded-[30px] items-center justify-center shadow-3xl text-primary-100 bg-white border-primary-100 border-2"
              >
                Unggah
              </button>
            </div>
          )}
          {showUploadModal && (
            <UploadOralModal setShowModal={setShowUploadModal} />
          )}

          {!oralFile && !isLoading && (
            <div className="bg-[#FEFAED] p-[20px] mt-4 rounded-[8px]">
              <div className="flex items-center gap-[4px]">
                <img src={information} alt="logo" />
                <p className="font-bold">Mengenai Unggah Presentasi Oral</p>
              </div>
              <ul>
                <li>
                  • Presentasi Oral hanya dapat diunggah oleh peserta yang
                  Abstract Disetujui sebagai Presentasi Oral
                </li>
                <li>• Presentasi Oral wajib diunggah dalam format PDF.</li>
                <li>
                  • Presentasi Oral hanya dapat diunggah sekali dan tidak dapat
                  direvisi
                </li>
                <li>
                  • Tenggat waktu pengumpulan presentasi pada{" "}
                  <span className="font-bold">
                    {hideTimestamp && convertTime(hideTimestamp)} WITA
                  </span>
                </li>
              </ul>
            </div>
          )}
          {isLoading && <LoadingSpinner />}
        </div>
      </div>
    </Layout>
  );
};

export default Oral;
