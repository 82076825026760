import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/images/konas_logo2.svg";
import abstractLogoRed from "../../assets/images/abstract_logo_red.svg";
import abstractLogoInactive from "../../assets/images/abstract_logo_inactive.svg";
import makalahLogoRed from "../../assets/images/makalah_logo_red.svg";
import makalahLogoInactive from "../../assets/images/makalah_logo_inactive.svg";
import posterLogoRed from "../../assets/images/poster_logo_red.svg";
import posterLogoInactive from "../../assets/images/poster_logo_inactive.svg";
import akunLogoRed from "../../assets/images/user_logo_red.svg";
import akunLogoInactive from "../../assets/images/user_logo_inactive.svg";
import broadcastLogoInactive from "../../assets/images/broadcast_inactive.svg";
import broadcastLogoActive from "../../assets/images/broadcast_active.svg";
import { FaLock } from "react-icons/fa";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { logoutAdmin } from "../../utils/auth";
import { useSnackbar } from "notistack";

interface LayoutProps {
  children: React.ReactNode;
}

const Sidebar = () => {
  const location = useLocation();
  const [active, setActive] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const handleLogOut = () => {
    logoutAdmin();
    enqueueSnackbar("Berhasil keluar dari akun", {
      autoHideDuration: 3000,
      variant: "warning",
      anchorOrigin: { vertical: "top", horizontal: "right" },
    });
    navigate("/login-admin");
  };

  const isRegistrasiActive = () => {
    return (
      location.pathname === "/admin/registrasi" ||
      location.pathname === "/admin/tambah-peserta" ||
      location.pathname === "/admin/daftar-peserta"
    );
  };

  const isBroadcastActive = () => {
    return (
      location.pathname === "/admin/broadcast/email" ||
      location.pathname === "/admin/broadcast/whatsapp"
    );
  };

  return (
    <div className="w-64 bg-white p-[25px]">
      <img src={logo} alt="logo" className="m-auto my-8" />
      <ul className="space-y-2">
        <li
          className={`p-4 rounded-r-[24px] ${
            active === "Registrasi" || isRegistrasiActive()
              ? "bg-primary-10 text-primary-100 font-extrabold hover:bg-primary-10 border-l-[10px] border-l-primary-100"
              : "text-[#828282] font-semibold hover:bg-[#EBEBEB]"
          }`}
        >
          <button
            onClick={() => {
              active === "Registrasi" ? setActive("") : setActive("Registrasi");
            }}
            className="flex flex-row w-full justify-between items-center"
          >
            <div className="flex flex-row gap-[12px]">
              <img
                src={
                  active === "Registrasi" || isRegistrasiActive()
                    ? akunLogoRed
                    : akunLogoInactive
                }
                alt="logo"
                className="w-[24px]"
              />
              <p>Registrasi</p>
            </div>
            <div className="text-[24px]">
              {active === "Registrasi" || isRegistrasiActive() ? (
                <RiArrowDropUpLine />
              ) : (
                <RiArrowDropDownLine />
              )}
            </div>
          </button>
        </li>
        {(active === "Registrasi" || isRegistrasiActive()) && (
          <Link to="/admin/registrasi">
            <li
              className={`px-4 py-2 rounded-r-[24px] font-semibold ${
                location.pathname === "/admin/registrasi"
                  ? "text-primary-50"
                  : "text-[#828282]"
              }`}
            >
              <button className="flex flex-row gap-[12px]">
                <p>Order Registrasi</p>
              </button>
            </li>
          </Link>
        )}
        {(active === "Registrasi" || isRegistrasiActive()) && (
          <Link to="/admin/tambah-peserta">
            <li
              className={`px-4 py-2 rounded-r-[24px]  text-[#828282] font-semibold ${
                location.pathname === "/admin/tambah-peserta"
                  ? "text-primary-50"
                  : "text-[#828282]"
              }`}
            >
              <button className="flex flex-row gap-[12px]">
                <p>Tambah Peserta</p>
              </button>
            </li>
          </Link>
        )}
        {(active === "Registrasi" || isRegistrasiActive()) && (
          <Link to="/admin/daftar-peserta">
            <li
              className={`px-4 py-2 rounded-r-[24px]  text-[#828282] font-semibold ${
                location.pathname === "/admin/daftar-peserta"
                  ? "text-primary-50"
                  : "text-[#828282]"
              }`}
            >
              <button className="flex flex-row gap-[12px]">
                <p>Daftar Peserta</p>
              </button>
            </li>
          </Link>
        )}
        <li
          className={`p-4 rounded-r-[24px] ${
            location.pathname === "/admin/abstract"
              ? "bg-primary-10 text-primary-100 font-extrabold hover:bg-primary-10 border-l-[10px] border-l-primary-100"
              : "text-[#828282] font-semibold hover:bg-[#EBEBEB]"
          }`}
        >
          <Link
            to="/admin/abstract"
            className="flex flex-row gap-[12px]"
            onClick={() => {
              setActive("Abstrak");
            }}
          >
            <img
              src={
                location.pathname === "/admin/abstract"
                  ? abstractLogoRed
                  : abstractLogoInactive
              }
              alt="logo"
              className="w-[24px]"
            />
            <p>Abstract</p>
          </Link>
        </li>
        <li
          className={`p-4 rounded-r-[24px] ${
            location.pathname === "/admin/oral"
              ? "bg-primary-10 text-primary-100 font-extrabold hover:bg-primary-10 border-l-[10px] border-l-primary-100"
              : "text-[#828282] font-semibold hover:bg-[#EBEBEB]"
          }`}
        >
          <Link
            to="/admin/oral"
            className="flex flex-row justify-between items-center"
          >
            <div className="flex flex-row gap-[12px]">
              <img
                src={
                  location.pathname === "/admin/oral"
                    ? makalahLogoRed
                    : makalahLogoInactive
                }
                alt="logo"
                className="w-[24px]"
              />
              <p>Presentasi Oral</p>
            </div>
            {/* <FaLock size={14} /> */}
          </Link>
        </li>
        <li
          className={`p-4 rounded-r-[24px] ${
            location.pathname === "/admin/poster"
              ? "bg-primary-10 text-primary-100 font-extrabold hover:bg-primary-10 border-l-[10px] border-l-primary-100"
              : "text-[#828282] font-semibold hover:bg-[#EBEBEB]"
          }`}
        >
          <Link
            to="/admin/poster"
            className="flex flex-row justify-between items-center"
          >
            <div className="flex flex-row gap-[12px]">
              <img
                src={
                  location.pathname === "/admin/poster"
                    ? posterLogoRed
                    : posterLogoInactive
                }
                alt="logo"
                className="w-[24px]"
              />
              <p>Poster</p>
            </div>
            {/* <FaLock size={14} /> */}
          </Link>
        </li>
        <li
          className={`p-4 rounded-r-[24px] ${
            active === "Broadcast" || isBroadcastActive()
              ? "bg-primary-10 text-primary-100 font-extrabold hover:bg-primary-10 border-l-[10px] border-l-primary-100"
              : "text-[#828282] font-semibold hover:bg-[#EBEBEB]"
          }`}
        >
          <button
            onClick={() => {
              active === "Broadcast" ? setActive("") : setActive("Broadcast");
            }}
            className="flex flex-row w-full justify-between items-center"
          >
            <div className="flex flex-row gap-[12px]">
              <img
                src={
                  active === "Broadcast" || isBroadcastActive()
                    ? broadcastLogoActive
                    : broadcastLogoInactive
                }
                alt="logo"
                className="w-[24px]"
              />
              <p>Broadcast</p>
            </div>
            <div className="text-[24px]">
              {active === "Broadcast" || isBroadcastActive() ? (
                <RiArrowDropUpLine />
              ) : (
                <RiArrowDropDownLine />
              )}
            </div>
          </button>
        </li>
        {(active === "Broadcast" || isBroadcastActive()) && (
          <Link to="/admin/broadcast/whatsapp">
            <li
              className={`px-4 py-2 rounded-r-[24px] font-semibold ${
                location.pathname === "/admin/broadcast/whatsapp"
                  ? "text-primary-50"
                  : "text-[#828282]"
              }`}
              // onClick={() => setActive("Broadcast")}
            >
              <button className="flex flex-row gap-[12px]">
                <p>Whatsapp</p>
              </button>
            </li>
          </Link>
        )}
        {(active === "Broadcast" || isBroadcastActive()) && (
          <Link to="/admin/broadcast/email">
            <li
              className={`px-4 py-2 rounded-r-[24px] text-[#828282] font-semibold ${
                location.pathname === "/admin/broadcast/email"
                  ? "text-primary-50"
                  : "text-[#828282]"
              }`}
              // onClick={() => setActive("Broadcast")}
            >
              <button className="flex flex-row gap-[12px]">
                <p>Email</p>
              </button>
            </li>
          </Link>
        )}

        {/* <li className={`p-4 rounded-r-[24px]  text-[#828282] font-semibold}`}>
          <Link
            to="/admin/abstract"
            className="flex flex-row justify-between items-center"
          >
            <div className="flex flex-row gap-[12px]">
              <img
                src={
                  location.pathname === "/kartu-peserta"
                    ? kartuLogoRed
                    : kartuLogoInactive
                }
                alt="logo"
                className="w-[24px]"
              />
              <p>Kartu Peserta</p>
            </div>
            <FaLock size={14} />
          </Link>
        </li>
        <li className={`p-4 rounded-r-[24px] text-[#828282] font-semibold}`}>
          <Link
            to="/admin/abstract"
            className="flex flex-row justify-between items-center"
          >
            <div className="flex flex-row gap-[12px]">
              <img
                src={
                  location.pathname === "/sertifikat"
                    ? sertifikatLogoRed
                    : sertifikatLogoInactive
                }
                alt="logo"
                className="w-[24px]"
              />
              <p>Sertifikat</p>
            </div>
            <FaLock size={14} />
          </Link>
        </li> */}
        <li
          className={`p-4 rounded-r-[24px] ${
            location.pathname === "/admin/administrator"
              ? "bg-primary-10 text-primary-100 font-extrabold hover:bg-primary-10 border-l-[10px] border-l-primary-100"
              : "text-[#828282] font-semibold hover:bg-[#EBEBEB]"
          }`}
        >
          <Link
            to="/admin/administrator"
            className="flex flex-row gap-[12px]"
            onClick={() => {
              setActive("Abstrak");
            }}
          >
            <img
              src={
                location.pathname === "/admin/administrator"
                  ? abstractLogoRed
                  : abstractLogoInactive
              }
              alt="logo"
              className="w-[24px]"
            />
            <p>Administrator</p>
          </Link>
        </li>
        <li
          className={`p-4 rounded-r-[24px] ${
            active === "Akun"
              ? "bg-primary-10 text-primary-100 font-extrabold hover:bg-primary-10 border-l-[10px] border-l-primary-100"
              : "text-[#828282] font-semibold hover:bg-[#EBEBEB]"
          }`}
        >
          <button
            onClick={() => {
              active === "Akun" ? setActive("") : setActive("Akun");
            }}
            className="flex flex-row w-full justify-between items-center"
          >
            <div className="flex flex-row gap-[12px]">
              <img
                src={active === "Akun" ? akunLogoRed : akunLogoInactive}
                alt="logo"
                className="w-[24px]"
              />
              <p>Akun</p>
            </div>
            <div className="text-[24px]">
              {active === "Akun" ? (
                <RiArrowDropUpLine />
              ) : (
                <RiArrowDropDownLine />
              )}
            </div>
          </button>
        </li>
        {active === "Akun" && (
          <li className={`p-4 rounded-r-[24px]  text-[#828282] font-semibold}`}>
            <button onClick={handleLogOut} className="flex flex-row gap-[12px]">
              <p>Log Out</p>
            </button>
          </li>
        )}
      </ul>
    </div>
  );
};

const LayoutAdmin = ({ children }: LayoutProps) => {
  return (
    <div className="flex h-screen bg-[#F7F7F7]">
      <Sidebar />
      <div className="flex-1 p-[24px] overflow-y-scroll">{children}</div>
    </div>
  );
};

export default LayoutAdmin;
