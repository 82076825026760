import Layout from "../Layout";
import { useState, useEffect } from "react";
import { axiosInstanceUser } from "../../utils/axios";
import UnduhIcon from "../../assets/images/unduh.svg";
import KartuPesertaImage from "../../assets/images/kartu_peserta.svg";
import LoadingSpinner from "../../components/Loading";
import { useSnackbar } from "notistack";

const KartuPeserta = () => {
  const [name, setName] = useState("");
  const [imageUrl, setImageUrl] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setLoading(true);

        const userResponse = await axiosInstanceUser.get("/auth/profile");

        if (userResponse.status === 200) {
          setName(userResponse.data.result.data.name);
        } else {
          console.error("Error fetching user data");
          return;
        }

        const otherResponse = await axiosInstanceUser.get(
          "/kartu-peserta/my/show"
        );
        if (otherResponse.status === 200) {
          setImageUrl(otherResponse.data.result.url);
        } else {
          return;
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const handleUnduh = async () => {
    try {
      const response = await axiosInstanceUser.get(
        `/kartu-peserta/my/download`
      );
      window.open(response.data.result.url);
    } catch (error) {
      enqueueSnackbar("Gagal mengunduh kartu peserta", {
        autoHideDuration: 1000,
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    } finally {
    }
  };
  return (
    <Layout>
      <div className="flex flex-col gap-[16px]">
        <div className="w-full bg-white rounded-[16px] p-[24px] font-bold md:text-[32px]">
          <h1>Hello, {name} 👋🏻</h1>
        </div>
        <div className="flex flex-col w-full bg-white rounded-[16px] p-[24px] gap-[16px]">
          <h1 className="font-bold md:text-2xl">Kartu Peserta</h1>
        </div>

        <div className="flex flex-col bg-white rounded-[16px] p-[24px] gap-[16px]">
          {isLoading ? (
            <div className="m-auto">
              <LoadingSpinner />
            </div>
          ) : (
            <div className="flex flex-col items-center rounded-[20px] gap-[12px] w-fit px-4 py-6 shadow-3xl">
              <p className="font-bold">Kartu Peserta KONAS Perdoski 2024</p>
              <img
                src={imageUrl ? imageUrl : KartuPesertaImage}
                alt="kartu peserta"
                className="w-[300px]"
              />
              {imageUrl && (
                <button
                  className={`flex px-[40px] py-[8px] w-full m-auto rounded-[30px] items-center justify-center shadow-3xl text-white ${
                    imageUrl ? "bg-primary-100" : "bg-gray"
                  }`}
                  disabled={imageUrl ? false : true}
                  onClick={handleUnduh}
                >
                  <img src={UnduhIcon} alt="unduh" className="mr-1"></img>
                  Unduh
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default KartuPeserta;
