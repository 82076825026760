import { useEffect, useState } from "react";
import { axiosInstanceAdmin } from "../../utils/axios";
import axios, { AxiosError } from "axios";

interface Candidate {
  id: string;
  name: string;
  number: number;
  photoPath: string;
}

interface ErrorResponse {
  error: string;
  success: boolean;
}

interface Props {
  userId: string;
  physicalCode: string;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
}
const Card = ({ candidate, handlePilih }: any) => {
  return (
    <div className="flex flex-col w-[200px] gap-[20px] rounded-[8px] border-2 border-[#E5E5E5] py-[32px] px-[16px] items-center m-[10px]">
      <img
        src={candidate.photoPath}
        alt="profile"
        className="w-[150px] rounded-full object-cover object-top aspect-square"
      />
      <p className="font-bold w-[100%] whitespace-normal text-center">
        {candidate.name}
      </p>
      <button
        className="flex px-[40px] py-[8px] w-[130px] m-auto rounded-[30px] items-center justify-center border text-primary-100 bg-white border-primary-100 border-2"
        onClick={() => handlePilih(candidate)}
      >
        Pilih
      </button>
    </div>
  );
};
const PilihKandidat = ({ userId, physicalCode, setCurrentStep }: Props) => {
  const [activeCandidate, setActiveCandidate] = useState<Candidate>();
  const [candidates, setCandidates] = useState<Candidate[]>([]);

  useEffect(() => {
    const getCandidate = async () => {
      try {
        const response = await axiosInstanceAdmin.get("/admin/vote/candidates");
        setCandidates(response.data.result);
      } catch (error) {}
    };
    getCandidate();
  }, []);

  const sortedCandidates = candidates.sort((a, b) => a.number - b.number);

  const handlePilih = (candidate: Candidate) => {
    (document.getElementById("my_modal_4") as HTMLDialogElement)?.showModal();
    setActiveCandidate(candidate);
  };

  const handleSubmitPilih = async () => {
    const payload = {
      candidateId: activeCandidate?.id,
      pesertaId: userId,
      physicalCode: physicalCode,
    };

    try {
      const response = await axiosInstanceAdmin.post("/admin/vote", payload);
      if (response.data.success) {
        setCurrentStep(4);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          const response = axiosError.response.data as ErrorResponse;
          if (response.error === "Already voted") {
            setCurrentStep(5);
          } else {
            setCurrentStep(6);
          }
        }
      }
    }
  };

  return (
    <div className="flex flex-wrap justify-center gap-[20px]">
      {sortedCandidates.map((candidate) => (
        <Card
          key={candidate.id}
          candidate={candidate}
          handlePilih={handlePilih}
        />
      ))}
      <dialog id="my_modal_4" className="modal">
        <div className="modal-box w-[500px] max-w-2xl bg-white">
          <h3 className="font-bold text-lg text-center">
            Konfirmasi Pemilihan
          </h3>
          <p className="py-4">
            Apakah kamu telah yakin memilih
            <span className="font-bold"> "{activeCandidate?.name}" </span>
            sebagai calon ketua?
          </p>
          <div className="modal-action flex flex-col">
            <form method="dialog">
              <button
                className="btn text-white bg-primary-100 border-0 rounded-[30px] p-[8px] w-full mb-2"
                onClick={handleSubmitPilih}
              >
                Ya
              </button>
              <button className="btn bg-white text-primary-100 border-0 hover:bg-[#C1C1C1] rounded-[30px] hover:text-white mr-4 w-full">
                Pilih Ulang
              </button>
            </form>
          </div>
        </div>
      </dialog>
    </div>
  );
};

export default PilihKandidat;
