import { useState } from "react";
import { axiosInstanceAdmin } from "../../utils/axios";

interface Props {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setPhysicalCode: React.Dispatch<React.SetStateAction<string>>;
}

const KodeFisik = ({ setCurrentStep, setPhysicalCode }: Props) => {
  const [isCodeValid, setIsCodeValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [code, setCode] = useState("");

  const validateCode = (code: string) => {
    return code.length !== 0;
  };

  const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputCode = e.target.value;
    setCode(inputCode);

    if (validateCode(inputCode)) {
      setIsCodeValid(true);
    } else {
      setIsCodeValid(false);
    }
  };

  const handleSubmitCode = async () => {
    try {
      const payload = { physicalCode: code };
      const response = await axiosInstanceAdmin.post(
        `/admin/vote/verify-physical-code`,
        payload
      );
      setPhysicalCode(code);
      setCurrentStep(2);
    } catch (error) {
      setErrorMessage("Kode tidak valid");
    }
  };

  return (
    <div>
      <p className="font-bold text-[20px]">Masukkan Kode Fisik</p>
      <div className="flex gap-[20px]">
        <div className="w-full">
          <input
            type="text"
            id="phone"
            name="phone"
            value={code}
            onChange={handleCodeChange}
            className={`mt-1 py-2 px-4 border-[1px] rounded-md w-full bg-white border-[#BABDCC] text-black`}
            placeholder="Masukkan kode fisik di sini"
          />
          <p className="mt-1 text-sm text-red-500">{errorMessage}</p>
        </div>
        <button
          className={`flex px-[40px] py-[8px] w-[165px] m-auto rounded-[30px] items-center justify-center shadow-3xl text-white ${
            isCodeValid ? "bg-primary-100" : "bg-disabled"
          }`}
          onClick={handleSubmitCode}
          disabled={!isCodeValid}
        >
          Masukan
        </button>
      </div>
    </div>
  );
};

export default KodeFisik;
