import logo from "../assets/images/konas_logo.svg";

interface NavigationBarProps {
  isValid: boolean;
  currentStep: number;
  onNext: () => void;
}

const NavigationBar = ({
  isValid,
  currentStep,
  onNext,
}: NavigationBarProps) => {
  const steps = ["1. Registrasi", "2. Ringkasan & Pembayaran", "3. Selesai"];

  return (
    <header className="flex flex-col lg:flex-row justify-between lg:items-center px-8 md:px-16 py-5 border-b-2">
      <div className="flex items-center">
        <img src={logo} alt="konas logo" className="w-[60px] hidden sm:block" />
        <p className="sm:text-[20px] xl:text-[32px] font-bold sm:px-2">
          Registrasi Pendaftaran
        </p>
      </div>
      <div className="flex w-[280px] sm:w-[380px] xl:w-[470px] text-[10px] sm:text-[14px] xl:text-base justify-between">
        {steps.map((step, index) => (
          <span
            key={index}
            className={`font-bold ${
              index === currentStep ? "" : "text-[#CACACA]"
            }`}
          >
            {step}{" "}
            <span className="pl-2 xl:pl-4">
              {index < steps.length - 1 && ">"}
            </span>
          </span>
        ))}
      </div>
      {/* {currentStep === 0 && ( */}
      <button
        className={`px-[40px] py-[8px] rounded-[30px] lg:block hidden xl:block text-white ${
          isValid ? "bg-primary-100" : "bg-disabled"
        } ${currentStep === 0 ? "shadow-3xl" : "bg-white"}`}
        onClick={onNext}
        disabled={!isValid || currentStep !== 0}
      >
        Lanjut
      </button>
      {/* )} */}
    </header>
  );
};

export default NavigationBar;
