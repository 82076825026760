import Home from "../pages/Home";
import Registration from "../pages/Registration";
import Login from "../pages/LogIn";
import OTP from "../pages/OTP";
import Abstract from "../pages/dashboard/Abstract";
import Oral from "../pages/dashboard/Oral";
import Poster from "../pages/dashboard/Poster";
import KartuPeserta from "../pages/dashboard/KartuPeserta";
import Sertifikat from "../pages/dashboard/Sertifikat";
import Akun from "../pages/dashboard/Akun";
import PaymentResponse from "../components/PaymentResponse";
import RegistrationAdmin from "../pages/admin-dashboard/OrderRegistrasi";
import DaftarPeserta from "../pages/admin-dashboard/DaftarPeserta";
import TambahPeserta from "../pages/admin-dashboard/TambahPeserta";
import NotFound from "../pages/404";
import AbstractAdmin from "../pages/admin-dashboard/AbstractAdmin";
import LoginAdmin from "../pages/admin-dashboard/LoginAdmin";
import OTPAdmin from "../pages/admin-dashboard/OTPAdmin";
import Email from "../pages/admin-dashboard/Email";
import Whatsapp from "../pages/admin-dashboard/Whatsapp";
import OralAdmin from "../pages/admin-dashboard/OralAdmin";
import PosterAdmin from "../pages/admin-dashboard/PosterAdmin";
import Administrator from "../pages/admin-dashboard/Administrator";
import PilihKetua from "../pages/admin-dashboard/PilihKetua";

interface PageRouting {
  title: string;
  path: string;
  component: () => JSX.Element;
}

export const routes: PageRouting[] = [
  { title: "Welcome", path: "/", component: Home },
  { title: "Register", path: "/registration", component: Registration },
  { title: "Payment", path: "/payment", component: PaymentResponse },
  { title: "Log In", path: "/login", component: Login },
  { title: "OTP", path: "/otp", component: OTP },
  { title: "Log In Admin", path: "/login-admin", component: LoginAdmin },
  { title: "OTP Admin", path: "/otp-admin", component: OTPAdmin },
  { title: "404 Not Found", path: "/404", component: NotFound },
];

export const privateRoutes: PageRouting[] = [
  { title: "Abstract", path: "/abstract", component: Abstract },
  { title: "Presentasi Oral", path: "/oral", component: Oral },
  { title: "Poster", path: "/poster", component: Poster },
  {
    title: "Kartu Peserta",
    path: "/kartu-peserta",
    component: KartuPeserta,
  },
  { title: "Sertifikat", path: "/sertifikat", component: Sertifikat },
  { title: "Akun", path: "/akun-pending", component: Akun },
];

export const adminRoutes: PageRouting[] = [
  {
    title: "Admin Dashboard",
    path: "/admin/registrasi",
    component: RegistrationAdmin,
  },
  {
    title: "Daftar Peserta",
    path: "/admin/daftar-peserta",
    component: DaftarPeserta,
  },
  {
    title: "Daftar Peserta",
    path: "/admin/tambah-peserta",
    component: TambahPeserta,
  },
  {
    title: "Abstract",
    path: "/admin/abstract",
    component: AbstractAdmin,
  },
  {
    title: "Broadcast Email",
    path: "/admin/broadcast/email",
    component: Email,
  },
  {
    title: "Broadcast Whatsapp",
    path: "/admin/broadcast/whatsapp",
    component: Whatsapp,
  },
  {
    title: "Presentasi Oral",
    path: "/admin/oral",
    component: OralAdmin,
  },
  {
    title: "Poster",
    path: "/admin/poster",
    component: PosterAdmin,
  },
  {
    title: "Broadcast Whatsapp",
    path: "/admin/administrator",
    component: Administrator,
  },
  {
    title: "Pemilihan Calon Ketua Perdoski",
    path: "/pilih-ketua",
    component: PilihKetua,
  },
];
