import { IAbstractAdmin } from "../../pages/admin-dashboard/AbstractAdmin";
import { useState } from "react";
import { HiOutlineDotsVertical } from "react-icons/hi";

import { axiosInstanceAdmin } from "../../utils/axios";
import { useSnackbar } from "notistack";

interface AbstractStatusProps {
  data: IAbstractAdmin;
  toggle: boolean;
  setToggle: React.Dispatch<React.SetStateAction<boolean>>;
}

const AbstractStatus = ({ data, toggle, setToggle }: AbstractStatusProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(data.id);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
    // setSelectedItemId(data.id);
  };

  const declineItem = async () => {
    try {
      const payload = { status: "DECLINED" };
      const response = await axiosInstanceAdmin.put(
        `/admin/abstracts/${selectedItemId}`,
        payload
      );

      enqueueSnackbar("Abstrak berhasil ditolak", {
        autoHideDuration: 1000,
        variant: "success",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
      setToggle(!toggle);
    } catch (error) {
      enqueueSnackbar("Penolakan abstrak gagal dilakukan", {
        autoHideDuration: 1000,
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    } finally {
      setDropdownOpen(false);
    }
  };

  const approveItemOral = async () => {
    try {
      const payload = { status: "ORAL" };
      const response = await axiosInstanceAdmin.put(
        `/admin/abstracts/${selectedItemId}`,
        payload
      );

      enqueueSnackbar("Abstrak berhasil disetujui - oral", {
        autoHideDuration: 1000,
        variant: "success",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
      setToggle(!toggle);
    } catch (error) {
      enqueueSnackbar("Persetujuan abstrak gagal dilakukan", {
        autoHideDuration: 1000,
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    } finally {
      setDropdownOpen(false);
    }
  };

  const approveItemPoster = async () => {
    try {
      const payload = { status: "POSTER" };
      const response = await axiosInstanceAdmin.put(
        `/admin/abstracts/${selectedItemId}`,
        payload
      );

      enqueueSnackbar("Abstrak berhasil disetujui - poster", {
        autoHideDuration: 1000,
        variant: "success",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
      setToggle(!toggle);
    } catch (error) {
      enqueueSnackbar("Persetujuan abstrak gagal dilakukan", {
        autoHideDuration: 1000,
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    } finally {
      setDropdownOpen(false);
    }
  };

  const handleLihatAbstrak = async () => {
    try {
      const path = data.filePath.substring(data.filePath.indexOf("/") + 1);

      const response = await axiosInstanceAdmin.get(
        `/admin/abstracts/s3/${path}`
      );
      window.open(response.data.result.url, "_blank");

      setToggle(!toggle);
    } catch (error) {
      enqueueSnackbar("Gagal melihat abstrak", {
        autoHideDuration: 1000,
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    } finally {
    }
  };

  const handleUnduhAbstrak = async () => {
    try {
      const path = data.filePath.substring(data.filePath.indexOf("/") + 1);

      const response = await axiosInstanceAdmin.get(
        `/admin/abstracts/s3-download/${path}`
      );
      window.open(response.data.result.url);
      setToggle(!toggle);
    } catch (error) {
      enqueueSnackbar("Gagal mengunduh abstrak", {
        autoHideDuration: 1000,
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    } finally {
    }
  };
  return (
    <tr className="">
      <td className="px-6 py-4 whitespace-nowrap text-center max-w-[100px] text-ellipsis overflow-x-hidden">
        {data.id}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-center max-w-[250px] text-ellipsis overflow-x-hidden">
        {data.uploaderName}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-center max-w-[120px] text-ellipsis overflow-x-hidden">
        {data.kategori}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-center max-w-[250px] text-ellipsis overflow-x-hidden">
        {data.name}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-center max-w-[100px] text-ellipsis overflow-x-hidden">
        <button onClick={handleLihatAbstrak}>Lihat</button>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-center max-w-[200px] text-ellipsis overflow-x-hidden">
        <button onClick={() => toggleDropdown()}>
          <HiOutlineDotsVertical />
        </button>
        {isDropdownOpen && (
          <div className="origin-top-right absolute right-6 mt-2 w-36 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
            <div className="py-1">
              {data.status === "SUBMITTED" && (
                <>
                  <button
                    onClick={approveItemOral}
                    className="block px-4 w-full py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                  >
                    Menyetujui - Oral
                  </button>
                  <button
                    onClick={approveItemPoster}
                    className="block px-4 w-full py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                  >
                    Menyetujui - Poster
                  </button>
                  <button
                    onClick={declineItem}
                    className="block px-4 w-full py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                  >
                    Menolak
                  </button>
                </>
              )}
              <button
                onClick={handleUnduhAbstrak}
                className="block px-4 w-full py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
              >
                Unduh
              </button>
            </div>
          </div>
        )}
      </td>
    </tr>
  );
};

export default AbstractStatus;
