import {
  PendaftarContext,
  PendaftarContextType,
  IPendaftar,
} from "../../context/AppContext";
import { useEffect, useState, useContext } from "react";
import axios, { AxiosError } from "axios";
import { axiosInstance } from "../../utils/axios";
import uploadLogo from "../../assets/images/upload.svg";
import InfoIcon from "../InfoIcon";
import { useSnackbar } from "notistack";

interface RegistrationFormProps {
  role: string;
  formData: IPendaftar;
  isPrice: boolean;
  setFormData: React.Dispatch<React.SetStateAction<IPendaftar>>;
  setIsValid: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IWorkshop {
  id: number;
  name: string;
  price: number;
}

interface ErrorData {
  success: boolean;
  result: any;
  error: string;
}

const DokterForm = ({
  role,
  formData,
  setFormData,
  setIsValid,
  isPrice,
}: RegistrationFormProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [errorMsgNPA, setErrorMsgNPA] = useState("");
  const [errorMessageEmail, setErrorMessageEmail] = useState("");
  const [errorMessagePhone, setErrorMessagePhone] = useState("");
  const [errorMessageNIK, setErrorMessageNIK] = useState("");
  const [simposiumPrice, setSimposiumPrice] = useState(0);
  const [workshops, setWorkshops] = useState<IWorkshop[]>([]);
  const [workshopIds, setWorkshopIds] = useState<(number | string)[]>([]);
  const [pendamping, setPendamping] = useState<IWorkshop[]>([]);
  const [acara, setAcara] = useState<IWorkshop[]>([]);
  const { listPendaftar } = useContext(
    PendaftarContext
  ) as PendaftarContextType;

  useEffect(() => {
    const getWorkshopsData = async () => {
      try {
        if (formData.categoryId === 3 || formData.categoryId === 4) {
          const response = await axiosInstance.get(
            `/products/${formData.categoryId}`
          );
          setWorkshops(response.data.result.workshops);
          setAcara(response.data.result.acara);
          setPendamping(response.data.result.pendamping);
          setSimposiumPrice(response.data.result.basePrice);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    getWorkshopsData();
  }, [formData.categoryId]);

  const [errors, setErrors] = useState({
    name: false,
    phone: false,
    email: false,
    fileSTR: false,
    NPAIDI: false,
    nik: false,
  });

  const [previewUrl, setPreviewUrl] = useState<string | null>(null);

  useEffect(() => {
    if (formData.fileSTR === "") {
      setPreviewUrl(null);
    }
  }, [formData.fileSTR]);

  useEffect(() => {
    const getImageStrLink = async () => {
      try {
        const path = formData.fileSTR.substring(
          formData.fileSTR.indexOf("/") + 1
        );

        const response = await axiosInstance.get(`/upload/str/s3/${path}`);
        setPreviewUrl(response.data.result.url);
      } catch (error) {
        // console.log(error);
      }
    };
    if (formData.fileSTR) {
      getImageStrLink();
    }
  }, [formData.fileSTR]);

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const file = e.target.files && e.target.files[0];

      if (!file) {
        throw new Error("No file selected");
      }

      if (file) {
        if (file.type.startsWith("image/")) {
          setPreviewUrl(URL.createObjectURL(file));

          const formDataRequest = new FormData();
          formDataRequest.append("file", file);

          const response = await axiosInstance.post(
            "/upload/str",
            formDataRequest
          );

          if (response.status === 200) {
            const link = response.data.result.key;
            setFormData({ ...formData, fileSTR: link });
            setErrors({ ...errors, fileSTR: false });
            enqueueSnackbar("Unggah STR berhasil", {
              autoHideDuration: 1000,
              variant: "success",
              anchorOrigin: { vertical: "top", horizontal: "right" },
            });
          } else {
            setErrors({ ...errors, fileSTR: true });
            enqueueSnackbar("Mohon unggah file", {
              autoHideDuration: 1000,
              variant: "warning",
              anchorOrigin: { vertical: "top", horizontal: "right" },
            });
          }
        } else {
          setErrors({ ...errors, fileSTR: true });
        }
      }
    } catch (error) {
      setErrors({ ...errors, fileSTR: true });
      enqueueSnackbar("File gagal diunggah", {
        autoHideDuration: 1000,
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    }
  };

  const checkNotNull = () => {
    return (
      formData.name.length > 0 &&
      formData.phone.length > 0 &&
      formData.email.length > 0 &&
      formData.fileSTR.length > 0 &&
      formData.NPAIDI.length > 0
    );
  };

  const isAllNumbers = (input: string) => {
    return /^[0-9]+$/.test(input);
  };

  const validateInput = (name: string, value: any) => {
    const newErrors = { ...errors };

    if (name === "name") {
      newErrors.name = value === "";
    }

    if (name === "phone") {
      newErrors.phone =
        value === "" ||
        !isAllNumbers(value) ||
        value[0] === "0" ||
        (value[0] === "6" && value[1] === "2");
      if (value === "") {
        setErrorMessagePhone("Nomor telepon tidak boleh kosong");
      } else if (!isAllNumbers(value)) {
        setErrorMessagePhone(
          "Mohon masukkan nomor tanpa karakter lain seperti -"
        );
      } else if (value[0] === "0") {
        setErrorMessagePhone("No telepon tidak boleh diawali 0");
      } else if (value[0] === "6" && value[1] === "2") {
        setErrorMessagePhone("No telepon tidak boleh diawali 62");
      }
    }

    if (name === "email") {
      newErrors.email =
        value === "" ||
        !value
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      if (newErrors.email) {
        setErrorMessageEmail("Email tidak valid");
      }
    }

    if (name === "NPAIDI") {
      newErrors.NPAIDI = value === "";
    }

    if (name === "nik") {
      newErrors.nik = value.length !== 16 || !/^\d+$/.test(value);

      if (value.length !== 16) {
        setErrorMessageNIK("NIK harus terdiri atas 16 digit");
      } else if (!/^\d+$/.test(value)) {
        setErrorMessageNIK("NIK harus berupa angka");
      }
    }

    setErrors(newErrors);
  };

  useEffect(() => {
    if (
      !(
        errors.name ||
        errors.phone ||
        errors.NPAIDI ||
        errors.email ||
        errors.fileSTR
      ) &&
      checkNotNull()
    ) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [formData]);

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    validateInput(name, value);
  };

  useEffect(() => {
    if (workshops.length > 0) {
      const newArray: number[] = [];

      workshops.forEach((item) => {
        newArray.push(item.id);
      });

      setWorkshopIds(newArray);
    }
  }, [workshops]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    const eventId = parseInt(name, 10);

    setFormData((prevFormData) => {
      if (checked) {
        return {
          ...prevFormData,
          events: [...prevFormData.events, eventId],
        };
      } else {
        return {
          ...prevFormData,
          events: prevFormData.events.filter((id) => id !== eventId),
        };
      }
    });
  };

  const handleWorkshopCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, checked } = event.target;
    const eventId = parseInt(name, 10);

    setFormData((prevFormData) => {
      if (checked) {
        const resultArray = prevFormData.events.filter(
          (item) => !workshopIds.includes(item)
        );
        return {
          ...prevFormData,
          events: [...resultArray, eventId],
        };
      } else {
        return {
          ...prevFormData,
          events: prevFormData.events.filter((id) => id !== eventId),
        };
      }
    });
  };

  const checkNPAInDatabase = async () => {
    try {
      const response = await axiosInstance.get(
        `/orders/validate/${formData.NPAIDI}?kind=idi`
      );
      setErrors({ ...errors, NPAIDI: false });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          const responseData = axiosError.response.data as ErrorData;
          setErrorMsgNPA(responseData.error);
          setErrors({ ...errors, NPAIDI: true });
        }
      }
    }
  };

  const isNPAExists = () => {
    return listPendaftar.some(
      (user) => user.NPAIDI === formData.NPAIDI && user.id !== formData.id
    );
  };
  const checkNPAInListPendaftar = () => {
    if (isNPAExists()) {
      setErrors({ ...errors, NPAIDI: true });
      setErrorMsgNPA("NPA sudah didaftarkan sebelumnya");
    }
  };

  const checkEmailInDatabase = async () => {
    try {
      if (formData.email) {
        const response = await axiosInstance.get(
          `/orders/validate-email?email=${formData.email}`
        );
        setErrors({ ...errors, email: false });
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          const responseData = axiosError.response.data as ErrorData;
          setErrorMessageEmail(responseData.error);
          setErrors({ ...errors, email: true });
        }
      }
    }
  };

  const isEmailExists = () => {
    return listPendaftar.some(
      (user) => user.email === formData.email && user.id !== formData.id
    );
  };
  const checkEmailInListPendaftar = () => {
    if (isEmailExists()) {
      setErrors({ ...errors, email: true });
      setErrorMessageEmail("Email sudah didaftarkan sebelumnya");
    }
  };

  useEffect(() => {
    if (formData.NPAIDI) {
      const timeoutId = setTimeout(() => {
        checkNPAInListPendaftar();
        if (!isNPAExists()) {
          checkNPAInDatabase();
        }
      }, 1000);

      return () => clearTimeout(timeoutId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.NPAIDI]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      checkEmailInListPendaftar();
      if (!isEmailExists() && !errors.email) {
        checkEmailInDatabase();
      }
    }, 1000);

    return () => clearTimeout(timeoutId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.email]);

  return (
    <div className="flex flex-col gap-[28px]">
      <div className="flex flex-col gap-[20px] md:gap-[40px]">
        <div>
          <label
            htmlFor="name"
            className="block text-[12px] sm:text-xl font-bold"
          >
            Nama dan Gelar <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className={`mt-1 py-2 px-4 border-[1px] rounded-md w-full md:w-[616px] bg-white ${
              errors.name
                ? "border-red-500 focus:ring-0 focus:outline-none focus:border-red-500"
                : "border-[#BABDCC]"
            }`}
            placeholder="Masukan nama dan gelar anda di sini"
          />
          <p
            className={`mt-1 text-sm ${
              errors.name ? "text-red-500" : "text-gray-500"
            }`}
          >
            {" "}
            {errors.name
              ? "Nama dan gelar tidak boleh kosong"
              : "Masukan nama yang ingin dicantumkan dalam sertifikat"}
          </p>
        </div>

        <div>
          <label
            htmlFor="lastName"
            className="block text-[12px] sm:text-xl font-bold"
          >
            No telepon<span className="text-red-500">*</span>
          </label>
          +62
          <input
            type="text"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
            className={`ml-2 mt-1 py-2 px-4 border-[1px] rounded-md w-full md:w-[616px] bg-white ${
              errors.phone
                ? "border-red-500 focus:ring-0 focus:outline-none focus:border-red-500"
                : "border-[#BABDCC]"
            }`}
            placeholder="Masukan No Telepon anda di sini"
          />
          <p
            className={`mt-1 text-sm ${
              errors.phone ? "text-red-500" : "text-gray-500"
            }`}
          >
            {errors.phone
              ? errorMessagePhone
              : "Masukan nomor anda yang terdaftar di aplikasi WhatsApp"}
          </p>
        </div>

        <div>
          <label
            htmlFor="email"
            className="block text-[12px] sm:text-xl font-bold"
          >
            Email<span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            className={`mt-1 py-2 px-4 border-[1px] border-[#BABDCC] rounded-md w-full md:w-[616px] bg-white ${
              errors.email
                ? "border-red-500 focus:ring-0 focus:outline-none focus:border-red-500"
                : "border-[#BABDCC]"
            }`}
            placeholder="Masukan alamat Email anda di sini"
          />
          <p
            className={`mt-1 text-sm ${
              errors.email ? "text-red-500" : "text-gray-500"
            }`}
          >
            {errors.email ? errorMessageEmail : ""}
          </p>
        </div>

        <div>
          <label
            htmlFor="nik"
            className="block text-[12px] sm:text-xl font-bold"
          >
            NIK<span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            id="nik"
            name="nik"
            value={formData.nik}
            onChange={handleChange}
            required
            className={`mt-1 py-2 px-4 border-[1px] border-[#BABDCC] rounded-md w-full md:w-[616px] bg-white ${
              errors.nik
                ? "border-red-500 focus:ring-0 focus:outline-none focus:border-red-500"
                : "border-[#BABDCC]"
            }`}
            placeholder="Masukan NIK anda di sini"
          />
          <p
            className={`mt-1 text-sm ${
              errors.nik ? "text-red-500" : "text-gray-500"
            }`}
          >
            {errors.nik ? errorMessageNIK : ""}
          </p>
        </div>

        <div>
          <label
            htmlFor="noAnggota"
            className="block text-[12px] sm:text-xl font-bold"
          >
            Nomor Pokok Anggota IDI<span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            id="NPAIDI"
            name="NPAIDI"
            value={formData.NPAIDI}
            onChange={handleChange}
            required
            className={`mt-1 py-2 px-4 border-[1px] rounded-md w-full md:w-[616px] bg-white ${
              errors.NPAIDI
                ? "border-red-500 focus:ring-0 focus:outline-none focus:border-red-500"
                : "border-[#BABDCC]"
            }`}
            placeholder="Masukan nomor pokok anggota anda di sini"
          />
          <p
            className={`mt-1 text-sm ${
              errors.NPAIDI ? "text-red-500" : "text-gray-500"
            }`}
          >
            {errors.NPAIDI ? errorMsgNPA : ""}
          </p>
        </div>

        <div>
          <label
            htmlFor="file"
            className="block text-[12px] sm:text-xl font-bold mb-2"
          >
            Unggah STR <span className="text-red-500">*</span>
          </label>
          {previewUrl && (
            <img
              src={previewUrl}
              alt="File Preview"
              className="my-4 w-[400px] h-auto"
            />
          )}
          <label
            htmlFor="file"
            className="relative cursor-pointer rounded-[30px] bg-white text-primary-100 border-primary-100 border-[1px] font-semibold py-2 px-4 inline-flex items-center"
          >
            <img src={uploadLogo} alt="logo" />
            <span>{previewUrl ? "Unggah Ulang" : "Unggah"}</span>
            <input
              type="file"
              accept="image/*"
              id="file"
              name="file"
              onChange={handleFileChange}
              className="sr-only"
            />
          </label>
          <p
            className={`mt-1 text-sm ${
              errors.fileSTR ? "text-red-500" : "text-gray-500"
            }`}
          >
            {errors.fileSTR ? "Format file tidak sesuai" : ""}
          </p>
        </div>

        <div>
          <div className="flex gap-[10px]">
            <label
              htmlFor="acara"
              className="block text-[12px] sm:text-xl font-bold"
            >
              Acara yang ingin diikuti
            </label>
            <InfoIcon />
          </div>
          <div className="flex flex-col md:flex-row gap-4 md:gap-0">
            <div className="pr-12">
              <label className="container">
                {" "}
                <span className="text-[#9B9B9B]">Simposium</span>
                <input
                  type="checkbox"
                  name="simposium"
                  checked={true}
                  disabled={true}
                />
                <span className="checkmark checkmark-disabled"></span>
              </label>
              {isPrice && (
                <p className="font-semibold text-[14px] text-[#C1C1C1]">
                  <span className="text-[#DC989E]">
                    Rp.
                    {simposiumPrice && simposiumPrice.toLocaleString("id-ID")}
                  </span>{" "}
                  per tiket
                </p>
              )}
            </div>
            {acara &&
              acara.map((acara) => (
                <div key={acara.id}>
                  <label className="container">
                    {acara.name}
                    <input
                      type="checkbox"
                      name={acara.id.toString()}
                      checked={formData.events.includes(acara.id)}
                      onChange={handleCheckboxChange}
                    />
                    <span className="checkmark"></span>
                  </label>
                  {isPrice && (
                    <p className="font-semibold text-[14px]">
                      <span className="text-primary-100">
                        Rp.{acara.price.toLocaleString("id-ID")}
                      </span>{" "}
                      per tiket
                    </p>
                  )}
                </div>
              ))}
          </div>
        </div>
        {workshops.length > 0 && (
          <div>
            <label
              htmlFor="workshop"
              className="block text-[12px] sm:text-xl font-bold"
            >
              Workshop
            </label>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 max-w-[920px]">
              {workshops &&
                workshops.map((workshop) => (
                  <div className="pr-12" key={workshop.id}>
                    <label className="container">
                      {workshop.name}
                      <input
                        type="checkbox"
                        name={workshop.id.toString()}
                        checked={formData.events.includes(workshop.id)}
                        onChange={handleWorkshopCheckboxChange}
                      />
                      <span className="checkmark"></span>
                    </label>
                    {isPrice && (
                      <p className="font-semibold text-[14px]">
                        <span className="text-primary-100">
                          Rp.{workshop.price.toLocaleString("id-ID")}
                        </span>{" "}
                        per tiket
                      </p>
                    )}
                  </div>
                ))}
            </div>
          </div>
        )}
        <div>
          <label
            htmlFor="pendamping"
            className="block text-[12px] sm:text-xl font-bold"
          >
            Pendamping
          </label>
          <div className="grid grid-cols-1 lg:grid-cols-4 gap-4 max-w-[920px]">
            {pendamping &&
              pendamping.map((pendamping) => (
                <div className="pr-12" key={pendamping.id}>
                  <label className="container">
                    {pendamping.name}
                    <input
                      type="checkbox"
                      name={pendamping.id.toString()}
                      checked={formData.events.includes(pendamping.id)}
                      onChange={handleCheckboxChange}
                    />
                    <span className="checkmark"></span>
                  </label>
                  {isPrice && (
                    <p className="font-semibold text-[14px]">
                      <span className="text-primary-100">
                        Rp.{pendamping.price.toLocaleString("id-ID")}
                      </span>{" "}
                      per tiket
                    </p>
                  )}
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DokterForm;
