import LayoutAdmin from "./LayoutAdmin";
import { useState, useEffect, useRef } from "react";
import { axiosInstanceAdmin } from "../../utils/axios";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { IoClose } from "react-icons/io5";
import BroadcastModal from "../../components/admin/BroadcastModal";
import { useSnackbar } from "notistack";
import BroadcastStatus from "../../components/admin/BroadcastStatus";
import { Pagination } from "../../components/Pagination";
import LoadingSpinner from "../../components/Loading";

interface Anggota {
  id: string;
  name: string;
}

interface Params {
  subject: string;
  message: string;
  recipient_types: number[];
}

export interface IBroadcast {
  params: Params;
  id: string;
  initiator: string;
  createdAt: string;
  updatedAt: string;
  sentTo: number;
  of: number;
}

const Whatsapp = () => {
  const [active, setActive] = useState("Buat Pesan");
  const [selectedMembers, setSelectedMembers] = useState<Anggota[]>([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [viewMessages, setViewMessages] = useState<IBroadcast[] | null>(null);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(5);
  const [totalItem, setTotalItem] = useState(0);
  const [loading, setLoading] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const { enqueueSnackbar } = useSnackbar();

  const members = [
    { id: "1", name: "Anggota Biasa Perdoski" },
    { id: "2", name: "Anggota Muda Perdoski" },
    { id: "4", name: "Dokter Umum" },
    { id: "3", name: "Dokter Spesialis Lain" },
  ];

  const next = () => {
    if (page === totalPage) return;

    setPage(page + 1);
  };

  const prev = () => {
    if (page === 1) return;

    setPage(page - 1);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleCheckboxChange = (member: Anggota) => {
    if (selectedMembers.some((m) => m.id === member.id)) {
      setSelectedMembers(selectedMembers.filter((m) => m.id !== member.id));
    } else {
      setSelectedMembers([...selectedMembers, member]);
    }
  };

  const handleChangeMessage = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value);
  };

  const handleSubmit = async () => {
    setShowConfirmationModal(false);
    const recipientTypes = selectedMembers.map((member) => parseInt(member.id));

    const payload = {
      message: message,
      recipient_types: recipientTypes,
      type: "phone",
    };

    try {
      const response = await axiosInstanceAdmin.post(
        "/admin/broadcast/send",
        payload
      );
      enqueueSnackbar("Pesan Broadcast berhasil terkirim", {
        autoHideDuration: 1000,
        variant: "success",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    } catch (error) {
      enqueueSnackbar("Pesan gagal dibroadcast", {
        autoHideDuration: 1000,
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    } finally {
      setMessage("");
      setSelectedMembers([]);
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    const getViewMessage = async () => {
      try {
        setLoading(true);
        const response = await axiosInstanceAdmin.get(
          `/admin/broadcast?page=${page}&type=phone`
        );
        setViewMessages(response.data.result.broadcasts);
        setTotalPage(response.data.result.totalPages);
        setTotalItem(response.data.result.total);
      } catch (error) {
        // console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    if (active === "Riwayat Pesan") {
      getViewMessage();
    }
  }, [active, page]);

  const isAbleToSubmit = message.length > 0 && selectedMembers.length > 0;

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        buttonRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        setDropdownOpen(false);
      }
    };

    if (dropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownOpen]);

  return (
    <LayoutAdmin>
      <div className="flex flex-col gap-[16px]">
        <div className="w-full bg-white rounded-[16px] p-[24px] font-bold md:text-[32px]">
          <h1>Broadcast</h1>
        </div>
        <div className="flex flex-col w-full bg-white rounded-[16px] p-[24px] gap-[16px]">
          <h1 className="font-bold md:text-2xl">Whatsapp Broadcast</h1>
          <div className="flex gap-[12px]">
            <button
              onClick={() => setActive("Buat Pesan")}
              className={`flex px-[12px] py-[6px] rounded-[20px] items-center justify-center ${
                active === "Buat Pesan"
                  ? "text-white bg-primary-100"
                  : "text-[#AFAFAF] bg-[#EBEBEB]"
              }`}
            >
              Buat Pesan
            </button>
            <button
              onClick={() => setActive("Riwayat Pesan")}
              className={`flex px-[12px] py-[6px] rounded-[20px] items-center justify-center ${
                active === "Riwayat Pesan"
                  ? "text-white bg-primary-100"
                  : "text-[#AFAFAF] bg-[#EBEBEB]"
              }`}
            >
              Riwayat Pesan
            </button>
          </div>
        </div>
        {active === "Buat Pesan" && (
          <div className="flex flex-col bg-white rounded-[16px] p-[24px] gap-[16px]">
            <div className="flex flex-row gap-[24px]">
              <p className="text-[18px] font-bold text-gray my-auto">
                Penerima
              </p>
              <div className="relative inline-block text-left">
                <button
                  ref={buttonRef}
                  type="button"
                  onClick={toggleDropdown}
                  className="inline-flex justify-center items-center w-full rounded-[20px] shadow-sm px-4 py-2 bg-primary-100 text-white rounded-[20px] "
                >
                  Kategori Peserta
                  {dropdownOpen ? (
                    <RiArrowDropUpLine className="ml-2" />
                  ) : (
                    <RiArrowDropDownLine className="ml-2" />
                  )}
                </button>

                {dropdownOpen && (
                  <div
                    ref={dropdownRef}
                    className="origin-top-right absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
                  >
                    <div className="py-1">
                      {members.map((member) => (
                        <div key={member.id} className="flex items-center p-2">
                          <input
                            type="checkbox"
                            id={member.id}
                            checked={selectedMembers.some(
                              (m) => m.id === member.id
                            )}
                            onChange={() => handleCheckboxChange(member)}
                            className="mr-2"
                          />
                          <label htmlFor={member.id} className="cursor-pointer">
                            {member.name}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="">
              {selectedMembers.map((member) => (
                <div
                  key={member.id}
                  className="inline-flex items-center px-3 py-1 mr-2 bg-[#FDEEEE] text-[#EB5757] rounded-[20px]"
                >
                  {member.name}
                  <button
                    className="ml-2"
                    onClick={() => handleCheckboxChange(member)}
                  >
                    <IoClose />
                  </button>
                </div>
              ))}
            </div>
            <div>
              <label
                htmlFor="pesan"
                className="block text-[14px] font-bold justify-left mb-1"
              >
                Pesan
              </label>
              <textarea
                value={message}
                onChange={handleChangeMessage}
                className={`pb-2 pt-2 px-4 border-[1px] rounded-md w-full bg-white border-[#BABDCC] h-48`}
                placeholder="Tulis pesan broadcast di sini"
              />
            </div>
            <div className="flex justify-end">
              <button
                disabled={!isAbleToSubmit}
                onClick={() => setShowConfirmationModal(true)}
                className={`flex px-[12px] py-[6px] rounded-[20px] items-center justify-center w-fit text-white ${
                  isAbleToSubmit ? "bg-primary-100" : "bg-[#D58289]"
                }`}
              >
                Kirim Broadcast
              </button>
              {showConfirmationModal && (
                <BroadcastModal
                  setShowModal={setShowConfirmationModal}
                  onSubmit={handleSubmit}
                />
              )}
            </div>
          </div>
        )}
        <div className="flex flex-col bg-white rounded-[16px] p-[24px] gap-[16px] items-center">
          {loading && <LoadingSpinner />}
          {active === "Riwayat Pesan" &&
            !loading &&
            viewMessages &&
            viewMessages.map((item) => (
              <BroadcastStatus
                type={"whatsapp"}
                broadcast={item}
                key={item.id}
              />
            ))}
        </div>
        {active === "Riwayat Pesan" && (
          <div className="flex justify-between px-4">
            <div>
              <span className="font-bold">{totalItem}</span> results
            </div>
            <Pagination
              page={page}
              totalPage={totalPage}
              next={next}
              prev={prev}
            />
          </div>
        )}
      </div>
    </LayoutAdmin>
  );
};

export default Whatsapp;
