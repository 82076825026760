import LottieAnimation from "./LottieAnimation";
import NavigationBar from "./NavigationBar";
import { Link } from "react-router-dom";

const PaymentResponse = () => {
  const queryParams = new URLSearchParams(window.location.search);

  const transactionStatus = queryParams.get("transaction_status");

  const success = ["capture", "settlement"];
  // const pending = ["pending", "refund", "partial_refund"];
  const failure = ["deny", "cancel", "expire", "failure"];

  const getOrderStatus = () => {
    if (success.includes(transactionStatus as string)) {
      return "success";
    } else if (failure.includes(transactionStatus as string)) {
      return "failure";
    } else {
      return "pending";
    }
  };

  const getTitle = () => {
    if (getOrderStatus() === "success") {
      return "Pembayaran Berhasil Dilakukan";
    } else if (getOrderStatus() === "failure") {
      return "Pembayaran Gagal Dilakukan";
    } else {
      return "Pembayaran Pending";
    }
  };

  const getExplanation = () => {
    if (getOrderStatus() === "success") {
      return "Silahkan cek email kamu untuk login ke akun yang sudah didaftarkan.";
    } else if (getOrderStatus() === "failure") {
      return "Silahkan cek email kamu";
    } else {
      return "Silahkan cek email kamu";
    }
  };

  return (
    <div className="min-h-[100vh] w-full">
      <NavigationBar isValid={false} currentStep={2} onNext={() => {}} />
      <div className="flex justify-center items-center m-auto h-[80vh]">
        <div className="flex flex-col xl:w-[30%] m-auto max-w-[400px] h-[80%] rounded-md p-[40px] gap-[36px] justify-center sm:shadow-3xl">
          <h1 className="text-[18px] font-bold text-center">{getTitle()}</h1>
          <LottieAnimation status={getOrderStatus} />
          <p className="font-medium text-center">{getExplanation()}</p>

          {getOrderStatus() === "success" && (
            <Link to="/login">
              <button className="flex px-[40px] py-[8px] w-full m-auto rounded-[30px] items-center justify-center shadow-3xl text-white bg-primary-100">
                OK
              </button>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentResponse;
