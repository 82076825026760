import NavigationBar from "../components/NavigationBar";
import RegistrationForm from "../components/RegistrationForm";
import Summary from "../components/Summary";

import { useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import {
  PendaftarContext,
  PendaftarContextType,
  IPendaftar,
} from "../context/AppContext";

const Registration = () => {
  const location = useLocation();
  const role = location.state?.selectedOption;
  const [currentStep, setCurrentStep] = useState(0);
  const [isValid, setIsValid] = useState(false);

  const getCategoryId = (role: string) => {
    if (role === "Anggota Biasa Perdoski (Sp.KK/ Sp.DV/ Sp.DVE)") {
      return 1;
    } else if (role === "Anggota Muda Perdoski (Residen)") {
      return 2;
    } else if (role === "Dokter Umum") {
      return 4;
    } else {
      return 3;
    }
  };

  const [formData, setFormData] = useState<IPendaftar>({
    id: 0,
    name: "",
    email: "",
    categoryId: getCategoryId(role),
    phone: "",
    NPAPerdoski: "",
    NPAIDI: "",
    cabang: "",
    fileSTR: "",
    events: [],
    nik: "",
  });
  const { savePendaftar } = useContext(
    PendaftarContext
  ) as PendaftarContextType;
  const nextStep = () => {
    setCurrentStep((step) => step + 1);
    savePendaftar(formData);
    setFormData({
      id: 0,
      name: "",
      email: "",
      categoryId: getCategoryId(role),
      phone: "",
      NPAPerdoski: "",
      NPAIDI: "",
      cabang: "",
      fileSTR: "",
      events: [],
      nik: "",
    });
  };

  return (
    <div
      className={`${
        currentStep === 0
          ? "max-h-[100vh] overflow-y-hidden"
          : "overflow-y-scroll"
      } max-h-[100vh] hide-scrollbar sm:min-h-screen w-full`}
    >
      <NavigationBar
        isValid={isValid}
        currentStep={currentStep}
        onNext={nextStep}
      />
      {currentStep === 0 && (
        <RegistrationForm
          role={role}
          formData={formData}
          setFormData={setFormData}
          isValid={isValid}
          onNext={nextStep}
          setIsValid={setIsValid}
        />
      )}
      {currentStep === 1 && (
        <Summary
          role={role}
          formData={formData}
          setFormData={setFormData}
          isValid={isValid}
          setIsValid={setIsValid}
          setCurrentStep={setCurrentStep}
        />
      )}
    </div>
  );
};

export default Registration;
