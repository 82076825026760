import infoIcon from '../assets/images/info_icon.svg';

const InfoIcon = () => {

  return (
    <div>
        <button onClick={() => (document.getElementById('my_modal_4') as HTMLDialogElement)?.showModal()}>
            <img src={infoIcon} alt='logo'/>
        </button>
    
        <dialog id="my_modal_4" className="modal">
            <div className="modal-box w-[400px]max-w-2xl bg-white">
                <h3 className="font-bold text-lg">Simposium</h3>
                <p className="py-4">Simposium merupakan acara yang wajib diikuti oleh seluruh peserta.</p>
                <div className="modal-action">
                <form method="dialog">
                    <button className="btn m-auto text-white bg-primary-100 border-0 rounded-[30px] p-[8px] w-[110px]">OK</button>
                </form>
                </div>
            </div>
        </dialog>
    </div>
  );
};

export default InfoIcon;