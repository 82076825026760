import Layout from '../Layout';

const Akun = () => {

  return (
    <Layout>
      <div>Coming soon</div>
    </Layout>
  );
};

export default Akun;