import UploadOralForm from "./UploadOralForm";

interface OralModalProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const UploadOralModal = ({ setShowModal }: OralModalProps) => {
  return (
    <div>
      <div className="pointer-events-none fixed inset-0 bg-black opacity-50 z-[60] cursor-pointer" />
      <div className="fixed inset-0 flex  items-center justify-center z-[70] ">
        <div className="flex flex-col w-[85%] max-w-[500px] m-auto rounded-[24px] md:rounded-[8px] shadow-[0_0px_20px_0px_rgb(25,61,111,0.25)] bg-white max-h-[90vh]">
          <div className="p-[24px] flex justify-between border-b border-gray-100">
            <h1 className="text-xl font-bold">Unggah Presentasi Oral</h1>
          </div>
          <form className="flex flex-col gap-[40px] px-8 py-4">
            <UploadOralForm setShowModal={setShowModal} />
          </form>
        </div>
      </div>
    </div>
  );
};

export default UploadOralModal;
