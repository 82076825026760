import { useEffect, useState } from "react";
import LayoutAdmin from "./LayoutAdmin";
import { axiosInstanceAdmin } from "../../utils/axios";
import { isDisabled } from "@testing-library/user-event/dist/utils";
import { useSnackbar } from "notistack";
import { config } from "../../utils/config";

export interface IAbstract {
  id: string;
  status: string;
  uploaderNpa: string;
  categoryId: number;
  title: string;
  filePath: string;
  createdAt: string;
}

export interface IUpload {
  file: any;
  title: string;
  categoryId: string;
}

export interface IAbstractAdmin {
  id: string;
  uploaderId: string;
  uploaderName: string;
  name: string;
  kategori: string;
  filePath: string;
  status: string;
}

interface KartuPesertaCount {
  uniqueCount: number;
  totalPeserta: number;
}

interface SertifikatCount {
  generated: number;
  total: number;
}

const Administrator = () => {
  const [isDisabledSertifikat, setIsDisabledSertifikat] = useState(false);
  const [isDisabledKartuPeserta, setIsDisabledKartuPeserta] = useState(false);
  const [isSuccessKartuPeserta, setIsSuccessKartuPeserta] = useState(false);
  const [isSuccessSertifikat, setIsSuccessSertifikat] = useState(false);

  const [kartuPesertaCount, setKartuPesertaCount] = useState<KartuPesertaCount>(
    { uniqueCount: 0, totalPeserta: 0 }
  );
  const [sertifikatCount, setSertifikatCount] = useState<SertifikatCount>({
    generated: 0,
    total: 0,
  });

  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const handleTerbitkanSertifikat = async () => {
    setIsDisabledSertifikat(true);
    try {
      enqueueSnackbar("Sertifikat sedang diterbitkan", {
        autoHideDuration: 1000,
        variant: "info",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
      const payload = { count: config.payload.kartuCount };
      const response = await axiosInstanceAdmin.post(
        "/admin/certificate/bulk",
        payload
      );
      setIsSuccessSertifikat(response.data.success);
    } catch (error) {
      enqueueSnackbar("Sertifikat gagal diterbitkan", {
        autoHideDuration: 1000,
        variant: "info",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    }

    setTimeout(() => {
      setIsDisabledSertifikat(false);
      setLoading(false);
    }, 10000);
  };

  const handleTerbitkanKartuPeserta = async () => {
    setIsDisabledKartuPeserta(true);
    setLoading(true);
    try {
      enqueueSnackbar("Kartu Peserta sedang diterbitkan", {
        autoHideDuration: 1000,
        variant: "info",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
      const payload = { count: config.payload.kartuCount };
      const response = await axiosInstanceAdmin.post(
        "/admin/kartu-peserta/generate-bulk",
        payload
      );
      setIsSuccessKartuPeserta(response.data.success);
    } catch (error) {
      enqueueSnackbar("Kartu Peserta gagal diterbitkan", {
        autoHideDuration: 1000,
        variant: "info",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    }

    setTimeout(() => {
      setIsDisabledKartuPeserta(false);
      setLoading(false);
    }, 10000);
  };

  useEffect(() => {
    const getKartuPesertacount = async () => {
      try {
        const response = await axiosInstanceAdmin.get(
          "/admin/kartu-peserta/aggregate"
        );
        setKartuPesertaCount(response.data.result);
      } catch (error) {}
    };

    const getSertifikatCount = async () => {
      try {
        const response = await axiosInstanceAdmin.get(
          "/admin/certificate/aggregate"
        );
        setSertifikatCount(response.data.result);
      } catch (error) {}
    };

    getKartuPesertacount();
    // getSertifikatCount();

    if (isSuccessKartuPeserta && !isDisabledKartuPeserta) {
      enqueueSnackbar("Kartu Peserta selesai diterbitkan", {
        autoHideDuration: 1000,
        variant: "success",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
      setIsSuccessKartuPeserta(false);
    }
  }, [enqueueSnackbar, isDisabledKartuPeserta, isSuccessKartuPeserta]);

  useEffect(() => {
    const getSertifikatCount = async () => {
      try {
        const response = await axiosInstanceAdmin.get(
          "/admin/certificate/aggregate"
        );
        console.log(response.data.result);
        setSertifikatCount(response.data.result);
      } catch (error) {}
    };

    getSertifikatCount();
  }, []);

  const isAllTerbit =
    kartuPesertaCount.uniqueCount === kartuPesertaCount.totalPeserta;

  const isAllTerbitSertifikat =
    sertifikatCount.generated === sertifikatCount.total;

  return (
    <LayoutAdmin>
      <div className="flex flex-col gap-[16px]">
        <div className="w-full bg-white rounded-[16px] p-[24px] font-bold text-[32px]">
          <h1>Administrator</h1>
        </div>
        <div className="flex flex-col w-full bg-white rounded-[16px] p-[24px] gap-[16px]">
          <h1 className="font-bold text-2xl">Sertifikat</h1>
          <button
            onClick={handleTerbitkanSertifikat}
            className={`flex w-fit px-[12px] py-[6px] rounded-[20px] items-center justify-center ${
              isDisabledSertifikat || isAllTerbitSertifikat
                ? "text-white bg-gray"
                : "text-white bg-primary-100"
            }`}
            disabled={isDisabledSertifikat || isAllTerbitSertifikat}
          >
            Terbitkan Sertifikat
          </button>
          <p className="font-bold">
            {sertifikatCount.generated} dari {sertifikatCount.total} Sertifikat
            telah terbit
          </p>
        </div>
        <div className="flex flex-col w-full bg-white rounded-[16px] p-[24px] gap-[16px]">
          <h1 className="font-bold text-2xl">Kartu Peserta</h1>
          <button
            onClick={handleTerbitkanKartuPeserta}
            className={`flex w-fit px-[12px] py-[6px] rounded-[20px] items-center justify-center ${
              isDisabledKartuPeserta || isAllTerbit
                ? "text-white bg-gray"
                : "text-white bg-primary-100"
            }`}
            disabled={isDisabledKartuPeserta || isAllTerbit}
          >
            {loading ? "Loading..." : "Terbitkan Kartu Peserta"}
          </button>
          <p className="font-bold">
            {kartuPesertaCount.uniqueCount} dari{" "}
            {kartuPesertaCount.totalPeserta} Kartu Peserta telah terbit
          </p>
        </div>
      </div>
    </LayoutAdmin>
  );
};

export default Administrator;
