import { IPosterAdmin } from "../../pages/admin-dashboard/PosterAdmin";
import { useState } from "react";
import { HiOutlineDotsVertical } from "react-icons/hi";

import { axiosInstanceAdmin } from "../../utils/axios";
import { useSnackbar } from "notistack";

interface PosterStatusProps {
  data: IPosterAdmin;
  toggle: boolean;
  setToggle: React.Dispatch<React.SetStateAction<boolean>>;
}

const PosterStatus = ({ data, toggle, setToggle }: PosterStatusProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleLihat = async () => {
    try {
      const path = data.filePath.substring(data.filePath.indexOf("/") + 1);
      const response = await axiosInstanceAdmin.get(
        `/admin/presentation/poster/show/${path}`
      );
      window.open(response.data.result.url, "_blank");

      setToggle(!toggle);
    } catch (error) {
      enqueueSnackbar("Gagal melihat abstrak", {
        autoHideDuration: 1000,
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    } finally {
    }
  };

  const handleUnduh = async () => {
    try {
      const path = data.filePath.substring(data.filePath.indexOf("/") + 1);

      const response = await axiosInstanceAdmin.get(
        `/admin/presentation/poster/s3-download/${path}`
      );
      window.open(response.data.result.url);
      setToggle(!toggle);
    } catch (error) {
      enqueueSnackbar("Gagal mengunduh abstrak", {
        autoHideDuration: 1000,
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    } finally {
    }
  };

  // console.log(data);
  return (
    <tr className="">
      <td className="px-6 py-4 whitespace-nowrap text-center max-w-[100px] text-ellipsis overflow-x-hidden">
        {data.id}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-center max-w-[250px] text-ellipsis overflow-x-hidden">
        {data.uploaderName}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-center max-w-[120px] text-ellipsis overflow-x-hidden">
        {data.uploaderCategory}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-center max-w-[250px] text-ellipsis overflow-x-hidden">
        {data.abstractCategory}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-center max-w-[120px] text-ellipsis overflow-x-hidden">
        {data.name}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-center max-w-[100px] text-ellipsis overflow-x-hidden">
        <button onClick={handleLihat}>Lihat</button>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-center max-w-[200px] text-ellipsis overflow-x-hidden">
        <button onClick={() => toggleDropdown()}>
          <HiOutlineDotsVertical />
        </button>
        {isDropdownOpen && (
          <div className="origin-top-right absolute right-6 mt-2 w-36 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
            <div className="py-1">
              <button
                onClick={handleUnduh}
                className="block px-4 w-full py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
              >
                Unduh
              </button>
            </div>
          </div>
        )}
      </td>
    </tr>
  );
};

export default PosterStatus;
