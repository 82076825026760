import LogoutButton from "../../assets/images/logout.svg";
import { useState, useEffect } from "react";
import { axiosInstanceAdmin } from "../../utils/axios";
import LoadingSpinner from "../../components/Loading";
import { logoutAdmin } from "../../utils/auth";
import KodeFisik from "../../components/pilih-ketua/KodeFisik";
import ScanQR from "../../components/pilih-ketua/ScanQR";
import PilihKandidat from "../../components/pilih-ketua/PilihKandidat";
import Result from "../../components/pilih-ketua/Result";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";

const PilihKetua = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [loading, setLoading] = useState(true);
  const [isCommittee, setIsCommittee] = useState(false);
  const [physicalCode, setPhysicalCode] = useState("");
  const [pesertaId, setPesertaId] = useState("");
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchIsCommittee = async () => {
      try {
        setLoading(true);
        const response = await axiosInstanceAdmin.get(
          `/admin/vote/check-election`
        );
        setIsCommittee(response.data.result);
      } finally {
        setLoading(false);
      }
    };
    fetchIsCommittee();
  }, []);

  const getTitle = () => {
    if (currentStep === 1) {
      return "Kode Fisik";
    } else if (currentStep === 2) {
      return "Scan QR";
    } else if (currentStep === 3) {
      return "Pilih Kandidat";
    } else if (currentStep === 4) {
      return "Pemilihan Berhasil";
    } else {
      return "";
    }
  };

  if (loading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div>
      {isCommittee && !loading ? (
        <div
          className={`flex flex-col bg-[#F7F7F7] items-center py-8 gap-[20px] ${
            currentStep !== 3 ? "h-screen" : ""
          }`}
        >
          <div className="flex justify-between items-center bg-white w-[1040px] rounded-[16px] px-[32px] py-[16px]">
            <p className="text-[32px] font-bold">{getTitle()}</p>
            <button
              onClick={() => {
                logoutAdmin();
                enqueueSnackbar("Berhasil keluar dari akun", {
                  autoHideDuration: 1000,
                  variant: "success",
                  anchorOrigin: { vertical: "top", horizontal: "right" },
                });
                navigate("/login-admin");
              }}
              className="flex gap-[10px] text-gray font-semibold"
            >
              <p>Log out</p>
              <img src={LogoutButton} alt="logout" />
            </button>
          </div>
          <div
            className={`flex flex-col gap-[10px] bg-white rounded-[16px] h-full px-[32px] py-[32px] ${
              currentStep !== 3 ? "w-[1040px]" : "w-[1250px]"
            }`}
          >
            {currentStep === 1 && (
              <KodeFisik
                setCurrentStep={setCurrentStep}
                setPhysicalCode={setPhysicalCode}
              />
            )}
            {currentStep === 2 && (
              <ScanQR setCurrentStep={setCurrentStep} setId={setPesertaId} />
            )}
            {currentStep === 3 && (
              <PilihKandidat
                setCurrentStep={setCurrentStep}
                userId={pesertaId}
                physicalCode={physicalCode}
              />
            )}
            {currentStep === 4 && (
              <Result
                setCurrentStep={setCurrentStep}
                type={"success"}
                message={"Pemilihan Berhasil"}
              />
            )}
            {currentStep === 5 && (
              <Result
                setCurrentStep={setCurrentStep}
                type={"failure"}
                message={"Anda sudah pernah memilih"}
              />
            )}
            {currentStep === 6 && (
              <Result
                setCurrentStep={setCurrentStep}
                type={"failure"}
                message={"Pemilihan Gagal"}
              />
            )}
          </div>
        </div>
      ) : (
        <div className="flex h-screen items-center justify-center">
          You're not allowed to access this page
        </div>
      )}
    </div>
  );
};

export default PilihKetua;
