import Layout from "../Layout";
import { useState, useEffect } from "react";
import { axiosInstanceUser } from "../../utils/axios";
import UnduhIcon from "../../assets/images/unduh.svg";
import KartuPesertaImage from "../../assets/images/kartu_peserta.svg";
import LoadingSpinner from "../../components/Loading";
import { useSnackbar } from "notistack";

interface SertifikatType {
  id: string;
  pesertaId: string;
  filePath: string;
  certificateId: string;
  createdAt: string;
  updatedAt: string;
  certificate_description: { name: string };
}

const Sertifikat = () => {
  const [name, setName] = useState("");
  const [sertifikat, setSertifikat] = useState<SertifikatType[]>([]);
  const [imageUrl, setImageUrl] = useState("");
  const [isLoading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setLoading(true);

        const userResponse = await axiosInstanceUser.get("/auth/profile");

        if (userResponse.status === 200) {
          setName(userResponse.data.result.data.name);
        } else {
          console.error("Error fetching user data");
          return;
        }

        const otherResponse = await axiosInstanceUser.get("/certificate");
        if (otherResponse.status === 200) {
          setSertifikat(otherResponse.data.result);
        } else {
          return;
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const handleUnduh = (image: string) => {
    try {
      if (image !== "") {
        window.open(image as any);
      }
    } catch (error) {
      enqueueSnackbar("Gagal mengunduh kartu peserta", {
        autoHideDuration: 1000,
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    } finally {
    }
  };
  return (
    <Layout>
      <div className="flex flex-col gap-[16px]">
        <div className="w-full bg-white rounded-[16px] p-[24px] font-bold md:text-[32px]">
          <h1>Hello, {name} 👋🏻</h1>
        </div>
        <div className="flex flex-col w-full bg-white rounded-[16px] p-[24px] gap-[16px]">
          <h1 className="font-bold md:text-2xl">Sertifikat</h1>
        </div>

        <div className="flex flex-col bg-white rounded-[16px] p-[24px] gap-[10px]">
          {isLoading ? (
            <div className="m-auto">
              <LoadingSpinner />
            </div>
          ) : (
            <div>
              {sertifikat.length > 0 &&
                sertifikat.map((item) => (
                  <div className="flex flex-col gap-[16px] sm:flex-row w-full sm:items-center justify-between rounded-[20px] px-4 py-6 shadow-3xl">
                    <p className="font-bold">
                      {item.certificate_description.name}
                    </p>

                    {item.filePath && (
                      <button
                        className={`flex px-[40px] py-[8px] w-fit rounded-[30px] items-center justify-center shadow-3xl text-white ${
                          item.filePath ? "bg-primary-100" : "bg-gray"
                        }`}
                        disabled={item.filePath ? false : true}
                        onClick={() => {
                          setImageUrl(item.filePath);
                          handleUnduh(item.filePath);
                        }}
                      >
                        <img src={UnduhIcon} alt="unduh" className="mr-1"></img>
                        Unduh
                      </button>
                    )}
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Sertifikat;
