import { IAbstract } from "../../pages/dashboard/Abstract";
import uploadLogo from "../../assets/images/upload.svg";
import UploadAbstractModal from "./UploadAbstractModal";
import { useState } from "react";

interface AbstractStatusProps {
  abstract: IAbstract;
  name: string;
}

const AbstractStatus = ({ abstract, name }: AbstractStatusProps) => {
  const [showUploadModal, setShowUploadModal] = useState(false);

  const rawDate = new Date(abstract.createdAt);

  const options = {
    year: "numeric" as const,
    month: "long" as const,
    day: "numeric" as const,
  };

  const formatted = rawDate.toLocaleDateString("ID-id", options);

  const getStyle = (status: string) => {
    if (status === "SUBMITTED") {
      return "bg-[#EDF1FD] text-[#4870EA]";
    } else if (status === "ACCEPTED") {
      return "bg-[#E6F4EE] text-[#008E58]";
    } else if (status === "DECLINED") {
      return "bg-[#FDEEEE] text-[#EB5757]";
    } else {
      return "bg-[#FDF5EE] text-[#F2994A]";
    }
  };

  return (
    <div className="flex flex-col md:flex-row rounded-[20px] py-[24px] px-[20px] shadow-3xl gap-[20px] md:gap-[150px] w-full">
      <div className="flex flex-col gap-[10px] w-full">
        <h1 className="font-bold">{abstract.title}</h1>
        <p>
          {name} / {abstract.categoryId === 1 ? "Laporan Kasus" : "Penelitian"}{" "}
          / {formatted}
        </p>

        {abstract.status === "REVISE" && (
          <button
            onClick={() => setShowUploadModal(true)}
            className="relative cursor-pointer rounded-[30px] w-[172px] bg-white text-primary-100 border-primary-100 border-[1px] font-semibold py-2 px-3 inline-flex items-center"
          >
            <img src={uploadLogo} alt="logo" />
            <span>Unggah Kembali</span>
          </button>
        )}
        {showUploadModal && (
          <UploadAbstractModal
            setShowModal={setShowUploadModal}
            isReupload={true}
          />
        )}
      </div>
      <div className="w-[100px]">
        <span
          className={`px-[10px] py-[4px] rounded-[30px] ${getStyle(
            abstract.status
          )}`}
        >
          {abstract.status}
        </span>
      </div>
    </div>
  );
};

export default AbstractStatus;
