import { useEffect } from "react";
import LottieAnimation from "../LottieAnimation";

interface Props {
  message: string;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  type: string;
}

const Result = ({ setCurrentStep, type, message }: Props) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentStep(2);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="flex flex-col gap-[40px] items-center justify-center">
      <p className="font-bold text-[28px]">{message}!</p>
      <div className="w-[480px] m-auto">
        <LottieAnimation
          status={() => (type === "success" ? "success" : "failure")}
        />
      </div>
      <p>
        Anda akan diarahkan ke halaman Scan QR Code Peserta dalam waktu 5 detik
      </p>
    </div>
  );
};

export default Result;
