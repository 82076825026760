export const cabang = [
    "Banda Aceh",
    "Balikpapan",
    "Bandar Lampung",
    "Banjarmasin",
    "Bandung",
    "Batam",
    "Bogor",
    "Denpasar",
    "Jakarta",
    "Jambi",
    "Jayapura",
    "Jember",
    "Malang",
    "Makassar",
    "Manado",
    "Mataram",
    "Medan",
    "Padang",
    "Palembang",
    "Palu",
    "Pekanbaru",
    "Pontianak",
    "Purwokerto",
    "Samarinda",
    "Semarang",
    "Serang",
    "Surabaya",
    "Surakarta",
    "Yogyakarta"
]