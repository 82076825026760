import React, { useState, useEffect } from "react";
import logo from "../../assets/images/konas_logo.svg";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../../utils/axios";
import axios, { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";
import { isAuthenticatedAdmin } from "../../utils/auth";

const LoginAdmin = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validateEmail = () =>
    email
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );

  const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (isSubmitting) return;

    setIsSubmitting(true);

    try {
      const response = await axiosInstance.post("/auth/login", {
        email: email.toLowerCase(),
      });
      navigate("/otp-admin", { state: { email } });
      enqueueSnackbar("OTP telah dikirimkan ke email", {
        autoHideDuration: 3000,
        variant: "success",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar("Email tidak terdaftar", {
          autoHideDuration: 3000,
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (isAuthenticatedAdmin()) {
      navigate("/admin/registrasi");
    }
  });

  return (
    <div className="flex justify-center m-auto h-[100vh]">
      <div className="flex flex-col xl:w-[30%] m-auto max-w-[400px] h-[70%] rounded-md p-[40px] sm:shadow-3xl">
        <img src={logo} alt="logo" className="w-[100px] mx-auto" />
        <h1 className="text-[22px] text-center mt-4">
          Welcome to Admin Login{" "}
        </h1>
        <p className="m-4">Masukkan email yang sudah didaftarkan</p>
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={handleChangeEmail}
            className="mt-1 py-2 px-4 my-1 border-[1px] border-[#BABDCC] rounded-md w-full bg-white"
            placeholder="Email address"
          />
          <p className="text-sm text-red-500">
            {email.length > 0 && !validateEmail() && "Email tidak valid"}
          </p>
          <button
            disabled={!validateEmail() || email.length === 0}
            type="submit"
            className={`flex px-[40px] py-[8px] my-4 w-full m-auto rounded-[30px] items-center justify-center shadow-3xl text-white ${
              !validateEmail() || email.length === 0
                ? "bg-disabled"
                : "bg-primary-100"
            }`}
          >
            Lanjut
          </button>
        </form>
      </div>
    </div>
  );
};

export default LoginAdmin;
