import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../Layout";
import illustration from "../../assets/images/no_abstract_illustration.svg";
import information from "../../assets/images/info.svg";
import { axiosInstance, axiosInstanceUser } from "../../utils/axios";
import AbstractStatus from "../../components/abstract/AbstractStatus";
import UploadAbstractModal from "../../components/abstract/UploadAbstractModal";
import LoadingSpinner from "../../components/Loading";
import axios, { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import { logoutUser } from "../../utils/auth";
import ViewAbstract from "../../components/abstract/ViewAbstract";
import { parseISO } from "date-fns";

export interface IAbstract {
  id: string;
  status: string;
  uploaderNpa: string;
  categoryId: number;
  title: string;
  filePath: string;
  createdAt: string;
}

export interface IAbstractView {
  id: string;
  uploaderId: string;
  uploaderName: string;
  name: string;
  kategori: string;
  status: string;
  createdAt: string;
}

export interface IUpload {
  file: any;
  title: string;
  categoryId: string;
}

const Abstract = () => {
  const [active, setActive] = useState("Unggah Abstract");
  const [name, setName] = useState("");
  const [abstract, setAbstract] = useState<IAbstract | null>(null);
  const [viewAbstracts, setViewAbstracts] = useState<IAbstractView[] | null>(
    null
  );
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingAbstract, setLoadingAbstract] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [hideTimestamp, setHideTimestamp] = useState<Date | null>(null);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userResponse = await axiosInstanceUser.get("/auth/profile");

        if (userResponse.status === 200) {
          setName(userResponse.data.result.data.name);
        } else {
          console.error("Error fetching user data");
          return;
        }

        const otherResponse = await axiosInstanceUser.get("/abstracts/user");
        if (otherResponse.status === 200) {
          setAbstract(otherResponse.data.result);
        } else {
          console.error("Error fetching other data");
          return;
        }
      } catch (error) {
        console.error("Error fetching data", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    const getDeadlineTime = async () => {
      try {
        const response = await axiosInstance.get(
          "/config?key=deadline-abstract"
        );
        setHideTimestamp(parseISO(response.data.result.value));
      } catch (error) {}
    };
    getDeadlineTime();
  }, []);

  useEffect(() => {
    if (hideTimestamp) {
      const intervalId = setInterval(() => {
        const currentTime = new Date();

        if (currentTime > hideTimestamp) {
          setIsVisible(false);
          setShowUploadModal(false);
          clearInterval(intervalId);
        }
      }, 1000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [hideTimestamp]);

  const convertTime = (time: Date) => {
    let options = {
      year: "numeric" as const,
      month: "long" as const,
      day: "numeric" as const,
      hour: "numeric" as const,
      minute: "numeric" as const,
      timeZone: "Asia/Singapore",
    };
    return time.toLocaleDateString("ID-id", options);
  };

  useEffect(() => {
    const getUserAbstract = async () => {
      try {
        const response = await axiosInstanceUser.get("/abstracts/user");
        setAbstract(response.data.result);
      } catch (error) {
        // console.error("Error fetching data:", error);
        if (axios.isAxiosError(error)) {
          const axiosError = error as AxiosError;
          if (axiosError.response && axiosError.response.status === 401) {
            enqueueSnackbar("Mohon log in kembali", {
              autoHideDuration: 3000,
              variant: "error",
              anchorOrigin: { vertical: "top", horizontal: "right" },
            });
            navigate("/login");
            logoutUser();
          }
        }
      }
    };
    getUserAbstract();
  }, [showUploadModal]);

  useEffect(() => {
    const getUserAbstract = async () => {
      try {
        setLoadingAbstract(true);
        const response = await axiosInstanceUser.get("/abstracts/user");
        setAbstract(response.data.result);
      } catch (error) {
        // console.error("Error fetching data:", error);
      } finally {
        setLoadingAbstract(false);
      }
    };

    const getViewAbstract = async () => {
      try {
        setLoadingAbstract(true);
        const response = await axiosInstanceUser.get(
          "/abstracts/accepted?page=1"
        );
        setViewAbstracts(response.data.result.abstracts);
      } catch (error) {
        // console.error("Error fetching data:", error);
      } finally {
        setLoadingAbstract(false);
      }
    };

    if (active === "Lihat Abstract") {
      getViewAbstract();
    } else {
      getUserAbstract();
    }
  }, [active]);

  const handleUpload = () => {
    setShowUploadModal(true);
  };

  return (
    <Layout>
      <div className="flex flex-col gap-[16px]">
        <div className="w-full bg-white rounded-[16px] p-[24px] font-bold md:text-[32px]">
          <h1>Hello, {name} 👋🏻</h1>
        </div>
        <div className="flex flex-col w-full bg-white rounded-[16px] p-[24px] gap-[16px]">
          <h1 className="font-bold md:text-2xl">Abstract</h1>
          <div className="flex gap-[12px]">
            <button
              onClick={() => setActive("Unggah Abstract")}
              className={`flex px-[12px] py-[6px] rounded-[20px] items-center justify-center ${
                active === "Unggah Abstract"
                  ? "text-white bg-primary-100"
                  : "text-[#AFAFAF] bg-[#EBEBEB]"
              }`}
            >
              Unggah Abstract
            </button>
            <button
              onClick={() => setActive("Lihat Abstract")}
              className={`flex px-[12px] py-[6px] rounded-[20px] items-center justify-center ${
                active === "Lihat Abstract"
                  ? "text-white bg-primary-100"
                  : "text-[#AFAFAF] bg-[#EBEBEB]"
              }`}
            >
              Lihat Abstract
            </button>
          </div>
        </div>

        <div className="flex flex-col bg-white rounded-[16px] p-[24px] items-center gap-[16px]">
          {!loading &&
            !loadingAbstract &&
            active === "Unggah Abstract" &&
            (abstract ? (
              <AbstractStatus name={name} abstract={abstract!} />
            ) : (
              <div className="flex flex-col w-full items-center">
                {isVisible && (
                  <>
                    <p className="font-bold text-[28px]">
                      Belum ada Abstract yang diunggah
                    </p>
                    <img src={illustration} alt="illustration"></img>
                    <button
                      onClick={handleUpload}
                      className="flex py-[8px] mt-8 h-[40px] px-[36px] rounded-[30px] items-center justify-center shadow-3xl text-primary-100 bg-white border-primary-100 border-2"
                    >
                      Unggah
                    </button>
                  </>
                )}

                {showUploadModal && (
                  <UploadAbstractModal
                    setShowModal={setShowUploadModal}
                    isReupload={false}
                  />
                )}
                <div className="bg-[#FEFAED] p-[20px] mt-4 rounded-[8px]">
                  <div className="flex items-center gap-[4px]">
                    <img src={information} alt="logo" />
                    <p className="font-bold">Mengenai unggah makalah</p>
                  </div>
                  <ul>
                    <li>• Satu peserta maksimum satu judul makalah</li>
                    <li>
                      • Silahkan unggah asbtract dalam format PDF. Format lain
                      akan ditolak oleh sistem.
                    </li>
                    <li>
                      • Tenggat waktu pengumpulan abstract pada{" "}
                      <span className="font-bold">
                        {hideTimestamp && convertTime(hideTimestamp)} WITA
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            ))}
          {!loadingAbstract &&
            active === "Lihat Abstract" &&
            viewAbstracts &&
            viewAbstracts.map((item) => (
              <ViewAbstract abstract={item} key={item.id} />
            ))}
          {(loading || loadingAbstract) && <LoadingSpinner />}
        </div>
      </div>
    </Layout>
  );
};

export default Abstract;
