import { useEffect, useState } from "react";
import { axiosInstanceAdmin } from "../../utils/axios";
import LottieAnimation from "../LottieAnimation";
import React from "react";
import Scanner from "../Scanner";

interface Props {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setId: React.Dispatch<React.SetStateAction<string>>;
}

const ScanQR = ({ setCurrentStep, setId }: Props) => {
  const [userId, setUserId] = useState("");
  const [step, setStep] = useState(1);
  const [categoryId, setCategoryId] = useState(0);

  useEffect(() => {
    const getUserData = async () => {
      try {
        if (userId !== "") {
          const response = await axiosInstanceAdmin.get(
            `/admin/users/${userId.split(":")[1]}`
          );
          setCategoryId(response.data.result.categoryId);
          setStep(2);
          if (
            response.data.result.categoryId === 1 ||
            response.data.result.categoryId === 2
          ) {
            setTimeout(() => {
              setCurrentStep(3);
            }, 3000);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    getUserData();
  }, [userId]);

  const handleResult = (text: string) => {
    setUserId(text);
    setId(text.split(":")[1]);
  };

  return (
    <div className="flex flex-col gap-[20px] items-center justify-center">
      {step === 1 && (
        <>
          <p className="font-bold text-[28px]">Scan QR Code Peserta</p>
          <div className="w-[80%] m-auto">
            <Scanner onScan={handleResult} />
          </div>
        </>
      )}
      {step === 2 &&
        (categoryId === 1 || categoryId === 2 ? (
          <>
            <p className="font-bold text-[28px]">Scan QR Peserta Berhasil!</p>
            <div className="w-[480px] m-auto">
              <LottieAnimation status={() => "success"} />
            </div>
          </>
        ) : (
          <>
            <p className="font-bold text-[28px]">Scan QR Peserta Gagal!</p>
            <div className="w-[480px] m-auto">
              <LottieAnimation status={() => "failure"} />
            </div>
            <p>QR Peserta tidak valid atau tidak terdaftar</p>
            <button
              className="flex px-[40px] py-[8px] w-[165px] m-auto rounded-[30px] items-center justify-center border text-primary-100 bg-white border-primary-100 border-2"
              onClick={() => {
                setUserId("");
                setStep(1);
              }}
            >
              Coba lagi
            </button>
          </>
        ))}
    </div>
  );
};

export default ScanQR;
