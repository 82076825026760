interface BroadcastModalProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit: any;
}

const BroadcastModal = ({ setShowModal, onSubmit }: BroadcastModalProps) => {
  return (
    <div>
      <div className="pointer-events-none fixed inset-0 bg-black opacity-50 z-[60] cursor-pointer" />
      <div className="fixed inset-0 flex  items-center justify-center z-[70] ">
        <div className="flex flex-col p-[30px] gap-[20px] w-[85%] max-w-[500px] m-auto rounded-[24px] md:rounded-[8px] shadow-[0_0px_20px_0px_rgb(25,61,111,0.25)] bg-white max-h-[90vh]">
          <h1 className="text-xl font-bold text-center">
            Kirim Pesan Broadcast
          </h1>
          <p>
            Pastikan kamu telah memeriksa tujuan penerima dan pesan yang ingin
            dikirim
          </p>
          <button
            className={`text-white border-0 rounded-[30px] p-[8px] bg-primary-100
            }`}
            onClick={onSubmit}
          >
            Kirim Broadcast
          </button>
          <button
            className="bg-white text-primary-100 border-0 hover:bg-[#C1C1C1] rounded-[30px] p-[8px] hover:text-white"
            onClick={() => setShowModal(false)}
          >
            Cek Kembali
          </button>
        </div>
      </div>
    </div>
  );
};

export default BroadcastModal;
