import QrScanner from "qr-scanner";
import { useEffect, useRef } from "react";

interface Props {
  onScan: (param: string) => void;
}
const Scanner = ({ onScan }: Props) => {
  const videoElementRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const video: HTMLVideoElement | null = videoElementRef.current;
    const qrScanner = new QrScanner(
      video as HTMLVideoElement,
      (result) => {
        onScan(result.data);
      },
      {
        returnDetailedScanResult: true,
        highlightScanRegion: true,
        highlightCodeOutline: true,
      }
    );
    qrScanner.start();

    return () => {
      qrScanner.stop();
      qrScanner.destroy();
    };
  }, []);

  return (
    <div>
      <div className="w-full">
        <video className="w-full" ref={videoElementRef} />
      </div>
    </div>
  );
};

export default Scanner;
