import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import { routes, privateRoutes, adminRoutes } from "./routing";
import { isAuthenticatedUser, isAuthenticatedAdmin } from "../utils/auth";

interface PrivateRouteProps {
  isAuthenticated: () => boolean;
}

interface AdminRouteProps {
  getIsAdmin: () => boolean;
}

const PrivateRoutes = ({ isAuthenticated }: PrivateRouteProps) => {
  return isAuthenticated() ? <Outlet /> : <Navigate to="/404" />;
};

const AdminRoutes = ({ getIsAdmin }: AdminRouteProps) => {
  return getIsAdmin() ? <Outlet /> : <Navigate to="/404" />;
};

export const Navigator = () => {
  return (
    <BrowserRouter>
      <Routes>
        {routes.map((route, index) => {
          const Component = route.component;
          return (
            <Route key={index} path={route.path} element={<Component />} />
          );
        })}
        <Route
          element={<PrivateRoutes isAuthenticated={isAuthenticatedUser} />}
        >
          {privateRoutes.map((route, index) => {
            const Component = route.component;
            return (
              <Route key={index} path={route.path} element={<Component />} />
            );
          })}
        </Route>
        <Route element={<AdminRoutes getIsAdmin={isAuthenticatedAdmin} />}>
          {adminRoutes.map((route, index) => {
            const Component = route.component;
            return (
              <Route key={index} path={route.path} element={<Component />} />
            );
          })}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
